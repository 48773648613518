import Api from './/api'
import { useEffect, useState } from 'react';
const {EXCLUDED_PM_ENTITYID_FOR_CHATBOT} = require('../constants.js');

export default function ChatBot(props) {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("accessToken");
    let userPMEntityId = props?.userDetails?.pmEntityID;
    const extractToken = () => {
        try {
                let cookieData = document.cookie;
                let cookieArray = cookieData.split(";");
                for (let i = 0; i < cookieArray.length; i++) {
                    if (cookieArray[i].indexOf("redirect") > -1 || cookieArray[i].indexOf(" redirect") > -1) {
                        let val = cookieArray[i].replace(/ /g, "").split("=")[1];
                        return val;
                    }
            }
        } catch (e) {
            console.error(e);
            return null;

        }
        return null;
    }

  const getBrowserType = () => {
    try {
      if (typeof window !== "undefined") {
        const standalone = window.navigator.standalone;
        const userAgent = window.navigator.userAgent.toLowerCase();
        const safari = userAgent.includes('safari');
        const ios = /iphone|ipod|ipad/.test(userAgent);

        if (ios) {
          if (!standalone && safari) {
            return "ChromeWeb";
          } else if (!standalone && !safari) {
            return "IOSWebView";
          }
        } else {
          if (userAgent.includes("wv")) {
            return "AndroidWebView";
          } else {
            return "SafariWeb";
          }
        }
      } else {
        return "Server";
      }
    } catch (error) {
       console.error(error)
    }
  }

    const [shortToken,setShortToken] = useState(accessToken);
    const [longToken,setLongToken] = useState("");
    const browserType=getBrowserType()
    const [hasScript, setHasScript] = useState(false);
    

  const handleToken = async () => {
    try {
      setLongToken(props.longToken);
      if (userPMEntityId && !Object.values(EXCLUDED_PM_ENTITYID_FOR_CHATBOT).includes(userPMEntityId)) {
        setHasScript(true);
      }

    } catch (error) {
      console.error(error);
    }
  }

    useEffect(()=>{
        handleToken();
    },[]);

    useEffect(() => {
        if (hasScript) {
            const script = document.createElement('script');
            script.src = '//in.fw-cdn.com/31749060/896280.js';
            script.setAttribute('chat', 'true');
            document.head.appendChild(script);
            window.fcSettings = {
                onInit: async function () {
                    window.fcWidget.conversation.setBotVariables({
                        userShortToken: shortToken,
                        userLongToken: longToken,
                        browserType:browserType
                      });
                }

            }
            window.fcWidgetMessengerConfig = {
                jsFunctions: {
                    redirectToNativeView :(Deeplink) => {
                      let androidDeeplink =Deeplink.androidDeeplink.trim();
                      let iosDeeplink=Deeplink.iosDeeplink.trim();
                      let webDeeplink=Deeplink.webDeeplink.trim();
                      let browserType=Deeplink.browserType.trim();
                        try{
                        switch (browserType) {
                          case "AndroidWebView":
                            window.WebToNativeCommunicationInterface.openDeeplink(androidDeeplink);
                            break;
                          case "IOSWebView":
                            window.webkit.messageHandlers.deepLinkUrl.postMessage(iosDeeplink);
                            break;
                          case "ChromeWeb":
                          case "SafariWeb":
                            if (window.location !== window.parent.location) {
                              window.parent.postMessage(
                                { openLink: webDeeplink },
                                "*"
                              );
                            } else {
                              window.open(webDeeplink, "_blank");
                            }
                            break;
                          default:
                            console.log(browserType + " : no deeplink implementation found");
                            break;
                        }
                      }catch(error){
                        console.log(error);
                        window.open(webDeeplink, "_blank");
                      }
                      }  ,

                    redirectToDialler: (data) => {
                        window.location.href=`tel:${data.phoneNumber}`
                    }
                }
            };
            return () => {
                document.head.removeChild(script);
            };
        }
    }, [hasScript,shortToken,longToken,browserType]);

    return null;
}
