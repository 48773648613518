import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import '../css/faq.scss';
import { faqsData, DAfaqsData } from '../data/faqData';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import $ from 'jquery';
import parse from 'html-react-parser';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Apis from '../helpers/event';
import Api from '../helpers/api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Helper from '../helpers'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { rule } from '../data/rule';

// List Imports
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import ImageIcon from '@material-ui/icons/Image';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ChatBot from '../helpers/chatbot'
import fetchUserDetailsFromParmsOrCookie from '../helpers/token-util';
import TypeForm from './typeform';
import { KEY_CHAIN } from '../constants';
import getOS from '../helpers/getBrowserType';
import SurgeryBanner from './surgeryBanner'
import ChatbotNotAccessible from '../helpers/isChatbotAccessible';

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(3)
  },
  listRoot: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: '#f7f7f7',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  rescheduleButton: {
    backgroundColor: rule.color.primary, 
    borderRadius: '4px', 
    color: 'white' 
  },
});

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: 'hello@medibuddy.in',
      mobile: '9999991555',
      params: (new URL(document.location)).searchParams,
      status: true,
      tabType: (new URL(document.location)).searchParams.get('type'),
      classes: this.props.classes,
      userEmail: null,
      userRequestId: null,
      userText: null,
      open: false,
      vertical: 'top',
      horizontal: 'center',
      snackBarMsg: 'Your request recorded successfully',
      listOpen: false,
      selectedValue: null,
      selectedConsult: null,
      secondRadioSelected: null,
      listButton: false,
      phoneNumber: null,
      consults: [],
      optionText: null,
      dialogText: '',
      dialogOpen: false,
      doctors: [],
      altDocImage: 'https://www.docsapp.in/website_assets/new-svg-images/doctor.svg',
      loading: true,
      loadingDoctors: [{id: 1}, {id: 2}, {id: 3}],
      reason: null,
      defaultEmail: null,
      defaultMobile: null,
      userDetails: null,
      userDetailsFetched: false,
      surgeryTypeFormLink: ""
    }
  }
  
  componentWillMount(){
    if(this.getMobileOperatingSystem() != 'Android'){
      if(!localStorage || !localStorage.gtaganalysis){
        localStorage.setItem('gtaganalysis', JSON.stringify({}))
      }
    }
    if (this.state.params.get('accessToken') || this.state.params.get('patientId')) {
      this.fetchPatientDetailsWithAccessToken();
      this.fetchPatientDetails();
      console.log('userDetails fetched')
    }
   
  }

  redirectToTypeform = () => {
    let typeformlink = KEY_CHAIN.SURGERY_TYPEFORM_LINK;
    let deviceOs = getOS();
    typeformlink = typeformlink + "platform=" + deviceOs;
    if(this.state.userDetails && this.state.userDetails.phone){
      typeformlink = typeformlink + "&phone=" + this.state.userDetails.phone;
    }
    window.location.assign(typeformlink);
  }

  componentDidMount() {
    const buttons = document.querySelectorAll('.typeform-button');
    buttons.forEach(button => {
      button.addEventListener('click', (event) => {
          this.redirectToTypeform(event);
      });
    });
  
    this.fetchUserDetailsFromParamsOrCookie();
    if(this.state.tabType){
      $('.tabContent_'+(this.state.tabType)).css({
        // "max-height": "1000vh",
        "display": "block",
        "padding": "1em"
      })
      $('.tabLabel_'+(this.state.tabType)).css({"background": "#fff"})
    }
    if(this.state.params.get('accessToken') || this.state.params.get('patientId')){
      // localStorage.setItem('accesstoken', this.state.params.get('accessToken'));
      this.fetchConsultsWithAccessToken();
    }
    Apis.pageView()
    // this.setState({dialogOpen: true, dialogText: "This is testing dialog"})
  }

  componentWillUnmount() {
    const button = document.getElementById('typeformButton');
    if (button) {
        button.removeEventListener('click', this.redirectToTypeform);
    }
}


  accordin = (data) => {
    return (
      <div className="tabs">
        <div className="tab">
          <input type="checkbox" id={data.id} />
          <label className="tab-label" for={data.id}> {data.title} </label>
          <div className="tab-content">
            {data.title}
          </div>
        </div>
      </div>
    )
  }

  fetchConsultsWithAccessToken = () => {
    let accesstoken = this.state.params.get('accessToken');
    let patientId = this.state.params.get('patientId');
    let payload = {};
    let flag = false;
    if(accesstoken){
      payload = {
        utm_source: 'medibuddy',
        accesstoken: accesstoken,
      }
      flag = true;
    } else if(patientId) {
      payload = {
        patientId: patientId
      }
      flag = true
    } else {}
    if(flag){
      Api.fetchConsults(payload).then(resp => {
        if(typeof(resp) === 'string') {
          resp = JSON.parse(resp)
        }
        if(resp.success) {
          this.setState({ consults: resp.consults })
        }
      }).catch(err => {

      })
    }
  }

  fetchPatientDetailsWithAccessToken = () => {
    let accesstoken = this.state.params.get('accessToken');
    let patientId = this.state.params.get('patientId');
    let payload = {};
    let flag = false;
    if(accesstoken){
      payload = {
        utm_source: 'medibuddy',
        accesstoken: accesstoken,
      }
      flag = true;
    } else if(patientId) {
      payload = {
        patientId: patientId
      }
      flag = true
    } else {}
    if(flag){
      Api.fetchUserDetails(payload).then(resp => {
        if(typeof(resp) === 'string') {
          resp = JSON.parse(resp)
          
        }
        if(resp && (resp.email || resp.phoneNumber)) {
          this.setState({ defaultEmail: resp.email })
          this.setState({ defaultMobile: resp.phoneNumber })
          this.setState({ userEmail: resp.email })
          this.setState({ phoneNumber: resp.phoneNumber })
        }
        console.log("State is set")
      }).catch(err => {

      })
    }
  }
  fetchPatientDetails = () => {
    let accessToken = this.state.params.get('accessToken');
    let headers = {};
    if (accessToken) {
      headers = {
        'accept': 'application/json, text/plain, */*',
        'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
        'accesstoken': accessToken,
      }
      Api.fetchPatientDetails(headers).then(resp => {
        if (typeof (resp) === 'string') {
          resp = JSON.parse(resp)

        }
        if (resp && resp.profile && (resp.profile.primaryEmailId || resp.profile.primaryPhoneNumber)) {
          this.setState({ userEmail: resp.profile.primaryEmailId })
          this.setState({ phoneNumber: resp.profile.primaryPhoneNumber })
        }
      console.log('userDetails fetched')
      }).catch(error => {
        console.log('Unable to set state')
      })
    }
    else {
      console.log('Unable to fetch details')
    }
  }


  fetchDoctors = (obj) => {
    let payload = {
      topic: obj.topic,
      consultationId: obj.id
    }
    Api.fetchDoctors(payload).then(resp => {
      if(typeof(resp) === 'string') {
        resp = JSON.parse(resp)
      }
      if(resp.bestDoctorsList.lenght === 0){
        this.setState({loading: false, dialogOpen: true, dialogText: 'Something went wrong, please contact customer support'});
        return;
      }
      this.setState({doctors: resp.bestDoctorsList, loading: false})
    }).catch(err => {
      this.setState({dialogOpen: true, dialogText: 'Something went wrong, please contact customer support'});
    })
  }

  handleListClick = () => {
    this.setState({listOpen: !this.state.listOpen})
  }

  handlRadioChange = (obj, faqObj ,event) => {
    this.setState({selectedValue: event.target.value, listOpen: true, selectedConsult: obj, listButton: false, secondRadioSelected: null, optionText: null})
    if(faqObj.fetchDoctors){
      this.setState({loading: true}, () => {
        this.fetchDoctors(obj);
      })
    }
  }

  handleSecondRadioChange = (optionText, event) => {
    this.setState({secondRadioSelected: event.target.value, listButton: true, optionText: optionText})
  }

  handleDoctorSubmit = (obj, event) => {
    event.preventDefault();
    if(obj.dropDown && !this.state.reason){
      this.setState({open: true, snackBarMsg: 'Please select the reason'});
      return;
    }
    let selectedConsult = this.state.selectedConsult;
    let payload = {
      topic : selectedConsult.topic,
      consultationId: selectedConsult.id,
      patientId: selectedConsult.patient,
      reassign: true,
      oldDoctor: false,
      newDoctorId: this.state.secondRadioSelected,
      info: obj.info,
      comments: this.state.reason
    }
    Api.doctorAction(payload).then(resp => {
      this.setState({dialogText: resp.comments, dialogOpen: true, secondRadioSelected: null, selectedValue: null, listButton: false, listOpen: false, reason: null})
    }).catch(err => {
      
    })
  }

  handleListSubmit = (event) => {
    event.preventDefault();
    let selectedConsult = this.state.selectedConsult;
    let reassign = this.state.optionText.includes('Reassign') ? true : false;
    let oldDoctor = this.state.optionText.includes('will wait') ? true : false;
    let payload = {
      topic : selectedConsult.topic,
      consultationId: selectedConsult.id,
      patientId: selectedConsult.patient,
      reassign: reassign,
      oldDoctor: oldDoctor,
      comments: this.state.reason
    }
    this.setState({listButton: false})
    Api.consultAction(payload).then(resp => {
      this.setState({dialogText: resp.comments, dialogOpen: true, secondRadioSelected: null, selectedValue: null, listButton: false, listOpen: false, reason: null})
      // this.fetchConsultsWithAccessToken();
    }).catch(err => {
      // this.fetchConsultsWithAccessToken();
    })
  }

  getLanguageString = (languages) => {
    try {
      let arr = JSON.parse(languages);
      var text = '';
      for(var i in arr) {
        text += (i == 0) ? arr[i] : ', ' + arr[i];
      }
      return text;
    } catch (err) {
      return languages;
    }
  }

  listComponent = (obj) => {
    // console.log("list called")
    var { classes } = this.props;
    return (
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Latest Consults
          </ListSubheader>
        }
        className={classes.listRoot}
      >
        {this.state.consults && this.state.consults.map((item, index) => {
          return (
            <ListItem button>
              <ListItemAvatar>
                <Avatar 
                  src={item.imageurl}
                  alt={this.state.altDocImage}
                  >
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item.doctorName} secondary={item.topic} />
              <ListItemSecondaryAction className="font-12">
                {Helper.getConsultDate(item.createdAt)}
                <Radio
                  checked={this.state.selectedValue === item.id + ''}
                  onChange={this.handlRadioChange.bind(this, item, obj)}
                  value={item.id + ''}
                  color="default"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': item.id + '' }}
                />
              </ListItemSecondaryAction>
              {(item.lenght === index + 1) ? (this.state.listOpen ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItem>
          )
        })}
        {obj.fetchDoctors ? ( <div>
          {this.state.doctors.length ? (
            <Collapse in={this.state.listOpen} timeout="auto" unmountOnExit>
              <div className='list-header'> {this.state.selectedConsult && this.state.selectedConsult.header} </div>
              <List component="div" disablePadding>
                {this.state.doctors.map((item, index) => {
                  return (
                    <div>
                      <ListItem alignItems="flex-start" className={classes.nested}>
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src={item.image} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.name}
                          secondary={
                            <React.Fragment>
                              <Typography component="span" variant="body2" className={classes.inline} color="textPrimary" >
                                {item.speciality}
                              </Typography>
                              <br/>
                              <Typography component="span" variant="body2" className={classes.inline} color="textPrimary" >
                                {item.experience} Years Experience
                              </Typography>
                              <br/>
                              {`Langauages - ${this.getLanguageString(item.languages)}`}
                            </React.Fragment>
                          }
                        />
                        <ListItemSecondaryAction className="font-12">
                          <Radio
                            checked={this.state.secondRadioSelected === item.doctorId + ''}
                            onChange={this.handleSecondRadioChange.bind(this, 'doctor assign')}
                            value={item.doctorId + ''}
                            color="default"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      {this.state.doctors.length == index + 1 ? null : (
                        <Divider variant="inset" component="li" />
                      )}
                    </div>
                  )
                })}                
              </List>
              <div className="center-align">
                <Button className={`list-submit-btn ${!this.state.listButton ? 'btn-disabled' : 'btn-on'}`} disabled={!this.state.listButton} style={{backgroundColor: rule.color.primary}} onClick={this.handleDoctorSubmit.bind(this, obj)} >Submit</Button>
              </div>
            </Collapse>
          ) : (
            <Collapse in={this.state.listOpen} timeout="auto" unmountOnExit>
              <div className='list-header'> {this.state.selectedConsult && this.state.selectedConsult.header} </div>
                <List component="div" >
                  {this.state.loading && this.state.loadingDoctors.map((item, index) => {
                    return (
                      <ListItem alignItems="flex-start" className={classes.nested}>
                        <ListItemAvatar>
                          <Skeleton animation="wave" variant="circle" width={40} height={40} />
                        </ListItemAvatar>
                        <ListItemText>
                          <Skeleton animation="wave" height={60} width="80%" style={{ marginBottom: 6 }} />
                        </ListItemText>
                      </ListItem>
                      // {this.state.loadingDoctors.length == index + 1 ? null : (
                      //   <Divider variant="inset" component="li" />
                      // )}
                    )
                  })}
              </List>
            </Collapse>
          )}
          </div> )
         : (
        <Collapse in={this.state.listOpen} timeout="auto" unmountOnExit>
          <div className='list-header'> {this.state.selectedConsult && this.state.selectedConsult.header} </div>
          <List component="div">
            {this.state.selectedConsult && this.state.selectedConsult.Options.map((item, index) => {
              return (
                <ListItem button className={classes.nested}>
                  <Radio
                    checked={this.state.secondRadioSelected === this.state.selectedConsult.id + '' + index}
                    onChange={this.handleSecondRadioChange.bind(this, item)}
                    value={this.state.selectedConsult.id + '' + index}
                    color="default"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': this.state.selectedConsult.id + '' + index }}
                  />
                  <ListItemText primary={item} />
                </ListItem>
              )
            })}
          </List>
          <div className="center-align">
            <Button className={`list-submit-btn ${!this.state.listButton ? 'btn-disabled' : 'btn-on'}`} disabled={!this.state.listButton} style={{backgroundColor: rule.color.primary}} onClick={this.handleListSubmit.bind(this)} >Submit</Button>
          </div>
        </Collapse>
        )}
      </List>
    )
  }

  dropDownHandleChange = (event) => {
    this.setState({reason: event.target.value})
  }

  dropDown = () => {
    const { classes } = this.props;
    return (
      <div>
        {/* <br/> */}
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Reason</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.reason}
            onChange={this.dropDownHandleChange}
          >
            <MenuItem value={'Doctor did not respond'}>Doctor did not respond</MenuItem>
            <MenuItem value={'Doctor was unprofessional'}>Doctor was unprofessional</MenuItem>
            <MenuItem value={'Doctor disconnected'}>Doctor disconnected</MenuItem>
            <MenuItem value={'Language'}>Language</MenuItem>
            <MenuItem value={'Others'}>Others</MenuItem>
          </Select>
        </FormControl>
      </div>
    )
  }

  dialogHandleClose = () => {
    this.setState({dialogOpen: false})
  }

  dialogComponent = () => {
    return (
      <Dialog onClose={this.dialogHandleClose} aria-labelledby="simple-dialog-title" open={this.state.dialogOpen}>
        <DialogContent>
          <div> {this.state.dialogText} </div>
          <div>{this.state.dialogText2} </div>
          <br/>
          <div className="center-align">
            <Button className={`list-submit-btn btn-on`} style={{backgroundColor: rule.color.primary}} onClick={this.dialogHandleClose} >Okay</Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  handleClick = () => {
    this.setState({ open: true});
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  getMobileOperatingSystem = () => {
    try {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
      }
  
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
  
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
  
      return 'unknown';
    } catch (err) {
      alert("file click error "+JSON.stringify(err))
      return 'unknown'
    }
  };

  closeTab(obj){
    this.sendGtag(obj)
    if((this.state.tabType) === obj.id){
      if(this.state.status){
        $('.tabContent_'+(this.state.tabType)).css({
          // "max-height": "0",
          "display": "none",
          "padding": "0 1em"
        })
        $('.tabLabel_'+obj.id).css({"background": "#fff"})
      } else {
        $('.tabContent_'+(this.state.tabType)).css({
          // "max-height": "1000vh",
          "display": "block",
          "padding": "1em"
        })
        $('.tabLabel_'+obj.id).css({"background": "#f8f8f8"})
      }
      this.setState({
        status: !this.state.status
      })
    }
  }

  openCall = async (obj) => {
    let payload = {};
    if(obj){
    payload = {
      event : obj.id,
      serviceName: 'faq-service-frontend',
      serviceType: 'faq-service-frontend',
      screen : 'Support',
      meta : JSON.stringify({
        phoneNumber : this.state.phoneNumber || null,
        email : this.state.userEmail || null,
        event_Name : 'callButtonClickEvent',
        platform : this.getMobileOperatingSystem()
      })
    }
   }
    window.location.href = "tel:" + obj.mobile;
    Apis.pushClickEvents(payload);
    console.log(payload.event)
  }

  phoneOption = (obj) => {
    return (
      <div onClick={this.openCall.bind(this, obj)}>
        <span className="vertical-middle"><CallIcon>Outlined</CallIcon></span>
        <span> {obj.mobile} </span>
      </div>
    )
  }

  fetchAccesstoken = () => {

    return this.state.params.get('accessToken') || "";
  }


  sendGtag = (obj) => {
    try {
      window.gtag("event", "faqSupport_" + obj.id , {
        event_category: "faqSupport_"+obj.id ,
        event_label: "faqSupport"+obj.id ,
      });

      window.dataLayer.push({ event:  "faqSupport_" + obj.id});
      if(obj.fetchDoctors){
        this.setState({listOpen: false, secondRadioSelected: null, selectedValue: null, listButton: false, listOpen: false, reason: null})
      }
      if(this.getMobileOperatingSystem() != 'Android' && !localStorage.gtaganalysis){
        localStorage.setItem('gtaganalysis', JSON.stringify({}))
      }
      var title = obj.emailClicked ? obj.title + " Email Clicked" : (obj.mobileClicked ? obj.title + " Mobile Clicked" : obj.title);
      var id = obj.emailClicked ? obj.id + "_Email_Clicked" : (obj.mobileClicked ? obj.id + "_Mobile_clicked" : obj.id);
      
      if(this.getMobileOperatingSystem() != 'Android'){
        var data = JSON.parse(localStorage.getItem('gtaganalysis'));
        if(data[id] && data[id]['timestamp'] && ((new Date()).getTime() - data[id]['timestamp']) > (24*60*60*1000)){
          Apis.send({category: 'User',action: title, label: id})
        } else if ( data[id] && data[id]['timestamp'] && ((new Date()).getTime() - data[id]['timestamp']) < (24*60*60*1000)){
          // Don't send event
        } else {
          data[id] = {timestamp: (new Date()).getTime(), title: title}
          localStorage.setItem('gtaganalysis', JSON.stringify(data))
          Apis.send({category: 'User', action: title, label: id})
        }
      } else {
        Apis.send({category: 'User', action: title, label: id})
      }
    } catch (error) {
      console.log(error.message)
      return error.message;
    }
  }

  openEmail = (obj) => {
    return (
      <a href={`mailto:${obj.email}`} onClick={this.sendGtag.bind(this, {...obj, emailClicked: true})} >
        <div>
          <span className="vertical-middle" ><EmailIcon> Outlined </EmailIcon></span>
          <span> {obj.email} </span>
        </div>
      </a>
    )
  }

  changeValue = (type, event) => {
    event.preventDefault();
    event.stopPropagation();
    // console.log(type + " - "+ event.target.value)
    if(type === 'email'){
      this.setState({userEmail: event.target.value})
    } else if(type === 'requestId') {
      this.setState({userRequestId: event.target.value})
    } else if (type === 'textInput') {
      this.setState({userText: event.target.value})
    } else if (type === 'phonenumber'){
      this.setState({phoneNumber: event.target.value})
    }
  }

  supportQuery = (obj, event) => {
    event.preventDefault();
    event.target.reset();
    var titleArr = obj.id.split('_');
    let titleString = '';
    for(var i in titleArr){
      titleString = (i == 0) ? titleArr[i] : (titleString + " | " + titleArr[i]);
    }
    var subject = `${titleString} | ${obj.title}`
    var source = (this.getMobileOperatingSystem() === 'Android') ? 'Android' : 'default'
    var requestObj = {
      emailId: this.state.userEmail || this.state.defaultEmail,
      requestId: this.state.userRequestId,
      text: this.state.userText,
      subject: subject,
      emailMapId: obj.email,
      utm_source: this.state.params.get('utm_source') || source || 'default',
      phoneNumber: this.state.phoneNumber || this.state.defaultMobile
    };

    let payloadMeta = {
      phoneNumber : this.state.phoneNumber || null,
      email : this.state.userEmail || null,
      event_Name : 'raiseTicketEvent',
      platform : this.getMobileOperatingSystem()
    }
    let payload = {
      event : obj.id,
      serviceName: 'faq-support-frontend',
      serviceType: 'faq-support-frontend',
      screen : 'Support',
      meta : JSON.stringify(payloadMeta)
    }
    Api.email(requestObj).then(resp => {
      // console.log("email sent successfully");
      this.setState({open: true, snackBarMsg: 'Your request recorded successfully', dialogOpen: true, dialogText: `Your request recorded successfully.`,dialogText2: `Your Ticket Number ${resp.ticket_id}`})
      Apis.send({category: 'User', action: titleString + " | Email Submitted", label: obj.id})
      // alert("Your request recorded successfully");
      Apis.pushClickEvents(payload)
    }).catch(err => {
      //check if status code is 429 then show limit exceeded
      if(err.response.status === 429){
        this.setState({
          open: true, 
          snackBarMsg: 'Limit reached, please try again after some time', 
          dialogOpen: true, 
          dialogText: 'Meanwhile you can reach us at',
          dialogText2: this.phoneOption(obj)
        })
        Apis.send({category: 'User', action: titleString + " | User limit reached", label: obj.id})
      } else { 
        this.setState({open: true, snackBarMsg: 'Error creating your request, please try again'})
        Apis.send({category: 'User', action: titleString + " | Email send failed", label: obj.id})
      }

      payloadMeta.event_Name = 'raiseTicketFailEvent';
      payload.meta = JSON.stringify(payloadMeta);
      Apis.pushClickEvents(payload)
    })
  }

  fetchUserDetailsFromParamsOrCookie = () => {
    fetchUserDetailsFromParmsOrCookie().then(resp => {
      if(resp) {
        this.setState({userDetails: resp.userInfo})
        this.setState({longToken: resp.longToken})
      } else {
        this.setState({userDetails: null})
      }
      this.setState({userDetailsFetched: true})
    }).catch(err => {
      this.setState({userDetails: null})
      this.setState({userDetailsFetched: true})
    });
  }

  buildItems = (items) => {
    const { classes } = this.props;

    return (
      <div className="tabs">
        {items.map(obj => {
          return (
            <div className={`tab child-tab`} >
              <input className="tab-input" type="checkbox" id={obj.id} />
              <label className={`tab-label child-label tabLabel_${obj.id}`} style={{color: rule.color.primary}} for={obj.id} onClick={obj.id === this.state.tabType ? this.closeTab.bind(this, obj) : this.sendGtag.bind(this, obj)}> {obj.title} </label>
              <div className={`tab-content child-content tabContent_${obj.id}`} style={{color: rule.color.primary}} >
                {obj.options ? this.buildItems(obj.options) : (
                  <div className='static-content'>
              <div>
                {obj.type === 'static' ? (
                  obj.htmlString ? (
                    obj.id === "ahc_rescheduling" ? (
                      <>
                      <div>{parse(obj.desc)}</div>
                      {/* <a href={`https://chatbotfrontend-ui.medibuddy.in?shortToken=${this.fetchAccesstoken()}`}>
                        <button className={classes.rescheduleButton} onClick={this.sendGtag.bind(this, { id: "faq_support_chatBotButtonClicked" })}>
                          Click to Reschedule
                        </button>
                      </a> */}
                      </>
                    ) : (
                      parse(obj.desc)
                    )
                  ) : (
                    parse(obj.desc)
                  )
                ) : (
                  obj.htmlString ? (
                    parse(obj.title)
                  ) : (
                    obj.title
                  )
                )}
              </div>                    
              {obj.dropDown ? this.dropDown() : null}
                    {obj.support && !obj.fetchConsults ? (
                      <div className="black-col">
                        {/* <div style={{paddingBottom: "2px", padding: "0.3rem"}}> {this.openEmail(obj)} </div>
                        <div style={{paddingBottom: "2px", padding: "0.3rem"}}> {this.phoneOption(obj)} </div> */}
                        <form className={classes.root} autoComplete="off" onSubmit={this.supportQuery.bind(this, obj)} >
                          <TextField
                            required
                            id={"outlined-required"+obj.id}
                            label="Your Email"
                            type="email"
                            value={obj.support ? this.state.defaultEmail : null}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={this.changeValue.bind(this, 'email')}
                            variant="outlined"
                          />
                          <TextField
                            required
                            id={"outlined-number"+obj.id}
                            label="Phone Number"
                            type="tel"
                            inputProps={{ 
                              pattern: "[0-9]{10}",
                              inputMode: 'numeric',
                              minLength: 10,
                              maxLength: 10 
                            }}
                            value= {obj.support ? this.state.defaultMobile : null}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={this.changeValue.bind(this, 'phonenumber')}
                            variant="outlined"
                          />
                          <TextField
                            required
                            id={"outlined-multiline-static"+obj.id}
                            label="Your concern"
                            placeholder={obj.placeholder ? obj.placeholder : "Request Id -             Concern - "}
                            multiline
                            rows={3}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={this.changeValue.bind(this, 'textInput')}
                            variant="outlined"
                          />
                          <Button className="submit-btn" style={{backgroundColor: rule.color.primary}} type='submit' >Submit</Button>
                        </form>
                            
                        {obj.mobile ? (
                          <div>
                            <div style={{marginTop: "16px"}}> Call us at </div>
                            <div style={{paddingBottom: "2px", padding: "0.3rem"}}> {this.phoneOption(obj)} </div>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div> 
                        <br/>
                          { obj.fetchConsults && this.state.consults && this.state.consults.length ? this.listComponent(obj) : (obj.support ? (
                            <div>
                              <form className={classes.root} autoComplete="off" onSubmit={this.supportQuery.bind(this, obj)} >
                                <TextField
                                  required
                                  id={"outlined-required" + obj.id}
                                  label="Your Email"
                                  type="email"
                                  value={obj.support ? this.state.defaultEmail : null}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={this.changeValue.bind(this, 'email')}
                                  variant="outlined"
                                />
                                <TextField
                                  required
                                  id={"outlined-multiline-static" + obj.id}
                                  label="Your concern"
                                  placeholder={obj.placeholder ? obj.placeholder : "Request Id -             Concern - "}
                                  multiline
                                  rows={3}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={this.changeValue.bind(this, 'textInput')}
                                  variant="outlined"
                                />
                                <Button className="submit-btn" style={{backgroundColor: rule.color.primary}} type='submit' >Submit</Button>
                              </form>

                              {obj.mobile ? (
                                <div>
                                  <div style={{ marginTop: "16px" }}> Call us at </div>
                                  <div style={{ paddingBottom: "2px", padding: "0.3rem" }}> {this.phoneOption(obj)} </div>
                                </div>
                              ) : null}
                            </div>) : null
                          )}
                      </div>
                    )}
                  </div>
                )}   
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const { classes } = this.props;
    var {vertical, horizontal} = this.state;
    return (
      <Container maxWidth="sm">
        <br/>
        {/* <Alert severity="info" style={{fontSize: '12px', backgroundColor: rule.color.disclaimer}}> Due to ongoing coronavirus pandemic, we are working with limited staff. Please expect a delay in response. Apologies for the inconvenience caused </Alert> */}
        <br/> <h2 style={{color: '#050a4e'}}><b> FAQs and Customer Support </b></h2> 
        <div className="tabs">
          {rule.data.map(obj => {
            return (
              <div className={`tab`} >
                <input className="tab-input" type="checkbox" id={obj.id} />
                <label className={`tab-label tabLabel_${obj.id}`} style={{color: rule.color.primary}} for={obj.id}  onClick={obj.id === this.state.tabType ? this.closeTab.bind(this, obj) : this.sendGtag.bind(this, obj)}> {obj.title} </label>
                <div className={`tab-content child-content tabContent_${obj.id}`} style={{color: rule.color.primary}} >
                  {obj.options ? this.buildItems(obj.options) : (
                    <>
                    <div>
                      <div>{obj.type === 'static' && obj.containsTypeForm === false ? (obj.htmlString ? parse(obj.desc) : obj.desc) : (obj.htmlString ? parse(obj.title) : obj.title)}</div>
                      {obj.type === 'static' && obj.containsTypeForm === true ? <div dangerouslySetInnerHTML={{__html: obj.desc}}/> : null}
                      {obj.support ? (
                        <div className="black-col col s12" style={{padding: "0.5rem"}}>
                          {/* <div style={{paddingBottom: "2px", padding: "0.3rem"}}> {this.openEmail(obj)} </div>
                          <div style={{paddingBottom: "2px", padding: "0.3rem"}}> {this.phoneOption(obj)} </div> */}
                          <form className={classes.root} autoComplete="off" onSubmit={this.supportQuery.bind(this, obj)} >
                            <TextField
                              required
                              id={"outlined-required"+obj.id}
                              label="Email"
                              type="email"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={this.changeValue.bind(this, 'email')}
                              variant="outlined"
                            />
                            {/* <TextField
                              required
                              id={"outlined-number"+obj.id}
                              label="Request Id"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={this.changeValue.bind(this, 'requestId')}
                              variant="outlined"
                            /> */}
                            <TextField
                              required
                              id={"outlined-multiline-static"+obj.id}
                              label="Your concern"
                              multiline
                              rows={3}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={this.changeValue.bind(this, 'textInput')}
                              variant="outlined"
                            />
                            <Button className="submit-btn" style={{backgroundColor: rule.color.primary}} type='submit' >Submit</Button>
                          </form>
                          {obj.mobile ? (
                          <div>
                            <div style={{marginTop: "16px"}}> Call us at </div>
                            <div style={{paddingBottom: "2px", padding: "0.3rem"}}> {this.phoneOption(obj)} </div>
                          </div>
                        ) : null}
                        </div>
                      ) : null}
                      
                    </div>
                    </>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          autoHideDuration={6000}
          open={this.state.open}
          onClose={this.handleClose}
          message={this.state.snackBarMsg}
          key={this.state.vertical + this.state.horizontal}
        >
        </Snackbar>
        {this.dialogComponent()}
        {this.state.userDetailsFetched ? <ChatBot userDetails={this.state.userDetails} longToken = {this.state.longToken}/> : null}
        {this.state.userDetailsFetched && ChatbotNotAccessible(this.state.userDetails) ? <TypeForm userDetails={this.state.userDetails} /> : null}
        {this.state.userDetailsFetched ? <SurgeryBanner userDetails={this.state.userDetails} /> : null}
      </Container>
    )
  }

}

export default withStyles(useStyles)(FAQ);