import React, { Component, PropTypes } from "react";
import Moment from "react-moment";
import moment from 'moment';

export class Helper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getConsultDate(timestamp) {
    return <Moment format="DD-MMM">{timestamp}</Moment>;
  }
}

export default Helper;