import {POST, GET} from './requestHelper'
import config from '../config';

const Api = {
  email: (payload) => {
    return POST('/patientswebapp/faq/ticket', payload);
  },
  fetchConsults: (payload) => {
    return POST('/patientswebapp/faq/fetchConsults', payload);
  },
  consultAction: (payload) => {
    return POST('/patientswebapp/faq/consult/action', payload);
  },
  fetchDoctors: (payload) => {
    return POST('/patientswebapp/faq/consult/getDoctors', payload);
  },
  doctorAction: (payload) => {
    return POST('/patientswebapp/faq/consult/docAction', payload);
  },
  fetchUserDetails: (payload) => {
    return POST('/patientswebapp/faq/consult/fetchEmailAndPhoneNumber', payload);
  },
  fetchPatientDetails : (headers) => {
    return GET(config.userProfileUrl , headers)
  },
  getLongToken : (body) => {
    return POST(config.getLongToken, body)
  },
  getPMEntityId : (data)=>{
    return POST(config.getUserProfile,data);
  }
}

export default Api;