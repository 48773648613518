
const EXCLUDED_PM_ENTITYID_FOR_CHATBOT = {
    TCS: 1006639,
    WELLS_FARGO: 11185545,
    RETAIL: 1018900
}

const KEY_CHAIN = {
    ANDROID_MWEB: "AndroidmWeb",
    IOS_MWEB: "iOSmWeb",
    REDIRECT: "redirect",
    ACCESS_TOKEN: "accessToken",
    SURGERY_TYPEFORM_LINK: "https://form.typeform.com/to/sYzvVH6P?",
    SURGERY_TOKEN: 'surgeryToken',
    WEB_SURGERY_BANNER_LINK: 'https://views.medibuddy.in/surgeryBanners/Web_SurgeryNewBanner.png',
    MOBILE_SURGERY_BANNER_LINK: 'https://views.medibuddy.in/surgeryBanners/mweb_SurgeryBanner.png'
}

module.exports = { EXCLUDED_PM_ENTITYID_FOR_CHATBOT, KEY_CHAIN }



