import { KEY_CHAIN } from "../constants";
import Api from "./api";
export default async function fetchUserDetailsFromParmsOrCookie() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      let userToken = urlParams.get(KEY_CHAIN.ACCESS_TOKEN);
      if(!userToken) {
        userToken = await fetchTokenFromCookie(KEY_CHAIN.REDIRECT);
      }
      let userInfo = null;
      let longTokenFetched = null;
      if(userToken && userToken.length > 32) {
        longTokenFetched = userToken;
        document.cookie = `${KEY_CHAIN.SURGERY_TOKEN}=${longTokenFetched};`;
        userInfo = await fetchUserDetailsFromLongToken(userToken);
      } else if(userToken && userToken.length === 32) {
        // We dont receive pm entity id from short token. So first fetching the long token and then calling the UserProfile API
        const longToken = await Api.getLongToken({shortToken:userToken})
        longTokenFetched = longToken.encryptedToken;
        document.cookie = `${KEY_CHAIN.SURGERY_TOKEN}=${longToken.encryptedToken};`;
        userInfo = await fetchUserDetailsFromLongToken(longToken.encryptedToken);
      }
      return {userInfo, longToken: longTokenFetched};
      
    } catch (error) {
      return null;
    } 
}
const fetchTokenFromCookie = async (name) => {
    try {
      const cookieArray = document.cookie.split(";");
      const cookie = cookieArray.find(item => item.trim().startsWith(`${name}=`));
      if (!cookie) return null;
      const token = cookie.substring(cookie.indexOf('=') + 1);
      return token || null;
    } catch (error) {
      return null;
    } 
}

const fetchUserDetailsFromLongToken = async(token) => {
  try {
    const payload = {
      isSSOLogin: true,
      authServerAccessToken: token
    }
    const response = await Api.getPMEntityId(payload); // this api fetch user profile data from long token
    if(response && response.user && response.user.userDetails) {
      return response.user.userDetails;
    } 
    return null;
  } catch (error) {
    return null;
  }
}