import axios from "axios";

let GET = (url, headers ) => {
  return new Promise((resolve, reject) => {
    axios.get(url,
      {
        headers: headers ? headers : { "Content-Type": "application/json" }
      }
    ).then(response => {
      resolve(response.data)
      // callback(null, response.data, false);
    }).catch(err => {
      try {
        reject(err.response.data.error)
        // callback(err.response.data.error, null);
      } catch (e) {
        console.log(err);
      }
    });
  })
}

let POST = (url, data) => {
  return new Promise((resolve, reject) => {
    axios.post(url, data,
      {
        headers: { "Content-Type": "application/json" }
      }
    ).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err);
    });
  })
}

export {
    POST, 
    GET
}