import { useEffect, useState } from "react";
import getOS from "../helpers/getBrowserType";
import { KEY_CHAIN } from "../constants";
export default function SurgeryBanner(props) {

    const [bannerLink, setBannerLink] = useState(KEY_CHAIN.MOBILE_SURGERY_BANNER_LINK)
    const addParams = () => {
        let typeformLink = KEY_CHAIN.SURGERY_TYPEFORM_LINK + "platform=" + getOS();
        if(props && props.userDetails && props.userDetails.phone) {
            typeformLink = typeformLink + "&phone=" + props.userDetails.phone;
        }
        return typeformLink;
    }
    useEffect(() => {
        const deviceOS = getOS();
        if(deviceOS === "Web") {
            setBannerLink(KEY_CHAIN.WEB_SURGERY_BANNER_LINK);
        } else {
            setBannerLink(KEY_CHAIN.MOBILE_SURGERY_BANNER_LINK);
        }
    }, [])
    const handleImageClick = () => {
        const link = addParams();
        window.location.assign(link);
    }
    return (
        <div>
            <img style={{width: "100%", cursor: "pointer", marginBottom: "10px"}} 
            src={bannerLink} 
            alt="Banner"
            onClick={handleImageClick}
             />
        </div>
    )
}