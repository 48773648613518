let config = {
  "production": {
    "host": "https://healthrisk.docsapp.in/hraapp",
    "healthData_url": "https://healthrisk.docsapp.in/hraapp/healthData",
    releaseEvents : {
      url : "https://eventlogs.docsapp.in/event"
    },
    userProfileUrl : "https://www.medibuddy.in/WAPI/UserProfile.json",
    getLongToken : "https://www.medibuddy.in/WAPI/GetUserLongToken",
    getUserProfile : "https://www.medibuddy.in/WAPI/MarketPlaceLogon"
  },
  "development": {
    "host": "http://localhost:3074",
    "healthData_url": "http://localhost:3074/healthData",
    releaseEvents : {
      url : "https://eventlogs.docsapp.in/event"
    },
    userProfileUrl : "https://www.medibuddy.in/WAPI/UserProfile.json",
    getLongToken : "https://www.medibuddy.in/WAPI/GetUserLongToken",
    getUserProfile : "https://www.medibuddy.in/WAPI/MarketPlaceLogon"

  }
}
export default config[process.env.NODE_ENV];