const faqs = [
  {
    id: 'ahc_1',
    title: 'AHC',
    type: 'dynamic',
    parent: null
  },
  {
    id: 'labs_1',
    title: 'Labs',
    type: 'dynamic',
    parent: null
  },
  {
    id: 'meds_1',
    title: 'Meds',
    type: 'dynamic',
    parent: null
  },
  {
    id: 'ahc_2',
    title: 'AHC Sub Type',
    type: 'dynamic',
    parent: 'ahc_1'
  },
  {
    id: 'labs_2',
    title: 'Labs Sub Type',
    type: 'dynamic',
    parent: 'labs_1'
  },
  {
    id: 'meds_2',
    title: 'Meds Sub Type',
    type: 'dynamic',
    parent: 'meds_1'
  },
  {
    id: 'ahc_3',
    title: 'AHC Level 3',
    type: 'dynamic',
    parent: 'ahc_3'
  },
  {
    id: 'ahc_4',
    title: 'AHC Level 4',
    type: 'dynamic',
    parent: 'ahc_3'
  }
]

// const faqsData = [
//   {
//     "id": "ahc",
//     "title": "Annual Health Checkup",
//     "type": "dynamic",
//     "options": [
//       {
//         "id": "ahc_book_appointment",
//         "title": "Book Appointment",
//         "type": "dynamic",
//         "options": [
//           {
//             "id": "ahc_book_appointment_2",
//             "title": "How should I book an appointment for a Health Checkup?",
//             "type": "static",
//             "htmlString": true,
//             "desc": "<div><div>You can book an appointment via the following steps:</div><ul><li> Choose your city</li><li> Choose your package </li><li>Confirm tests in your package<ul><li>Centre visit: Choose a date, time and location</li><li>Home sample collection: Enter your pin code and choose a date and time</li></ul></li><li> Add additional tests if needed </li><li> Book tests for family members </li><li> Confirm your contact information </li><li> Appointment confirmed</li></ul></div>"
//           }
//         ]
//       },
//       {
//         "id": "ahc_4",
//         "title": "Order Delayed",
//         "type": "dynamic",
//         "options": [
//           {
//             "id": "ahc_5",
//             "title": "Please go to order tracking page",
//             "type": "static",
//             "desc": "Your Order has been updated in the track order page",
//             "link": "https://www.medibuddy.in/"
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "id": "meds_1",
//     "title": "Meds",
//     "type": "dynamic",
//     "options": [
//       {
//         "id": "meds_2",
//         "title": "Order Cofirmation",
//         "type": "dynamic",
//         "options": [
//           {
//             "id": "meds_3",
//             "title": "Please go to order tracking page",
//             "type": "static",
//             "desc": "Your Order has been updated in the track order page",
//             "link": "https://www.medibuddy.in/"
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "id": "labs_1",
//     "title": "Labs",
//     "type": "dynamic",
//     "options": [
//       {
//         "id": "labs_2",
//         "title": "Please go to order tracking page",
//         "type": "static",
//         "desc": "Your Order has been updated in the track order page",
//         "link": "https://www.medibuddy.in/"
//       }
//     ]
//   }
// ]

let faqsData = [
  {
    "id": "Meds",
    "title": "Medicines",
    "type": "dynamic",
    "options": [
      {
        "id": "meds_delivery",
        "title": "Delivery Related",
        "type": "dynamic",
        "options": [
          {
            "id": "meds_delivery_2",
            "title": "I was not able to collect the order. Please attempt to deliver the order again",
            "type": "static",
            "desc": "Click here to enter order id/request id, name and mobile number separated by commas. Our customer support team will reach out to you and confirm delivery re-attempt",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
          {
            "id": "meds_delivery_3",
            "title": "My order is delayed",
            "type": "static",
            "htmlString": true,
            "desc": "<div> Your delivery might take longer than usual because of surge in demand, supply shortages and lockdown across cities. </div><br> <div><b> Home Delivery:</b> +1-2 days additional for delivery </div> <div> <b>Store pick-up: </b> 12-24 hours additional for your order to be ready </div> <div> <b>Courier: </b> +2-3 days additional for delivery </div><br> <div> If further support is required click below to enter order id/request id, name and mobile number separated by commas. Our customer support team will reach out to you </div>",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
   
          {
            "id": "meds_delivery_5",
            "title": "I did not receive all the medicines in my order",
            "type": "static",
            "desc": "Kindly write to us the original order details with images of the medicines delivered. Our support team can start processing your request as soon as we receive the order details and images.  ",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
          {
           "id": "meds_delivery_6",
           "title": "I received wrong medicines",
           "type": "static",
           "desc": "Kindly write to us the original order details with images of the medicines delivered. Our support team can start processing your request as soon as we receive the order details and images.",
           "support": true,
           "email": "medsupport@medibuddy.in"
          },
          {
            "id": "meds_delivery_7",
            "title": "Other",
            "type": "static",
            "desc": "Contact our team",
            "support": true,
            "email": "medsupport@medibuddy.in"
          }
        ]
      },
      {
        "id": "meds_order_status",
        "title": "Order Status / Confirmation Related",
        "type": "dynamic",
        "options": [
          {
            "id": "meds_order_status_2",
            "title": "I wanted to buy medicine under cashless",
            "type": "static",
            "htmlString": true,
            "desc": "<div>Kindly ensure medicines in your order are eligible for cashless benefits. Please look for the below labels in medicine search while placing an order,</div><br><div><b>Cashless:</b> </div><div> Medicine is available under cashless benefits </div> <br> <div><b> Policy Check Required:</b> </div> <div> Our team will do an eligibility check & approve eligible medicines for cashless benefits </div> <br> <div><b> Cash on Delivery / No label:</b> </div> <div> Medicine is not available under cashless benefits </div> <br>",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
          {
              "id": "meds_order_status_4",
              "title": "I placed the ordered by mistake",
              "type": "static",
              "htmlString": true,
              "desc": "<div> Cancellation is allowed only until your order is confirmed by our retailer. You can cancel your order from the track order section in our website or application <a href='https://www.medibuddy.in/?service=medicine' style='color: #2199FE'> https://www.medibuddy.in/?service=medicine. </a> Your order can no longer be cancelled/modified by you once retailer confirms your order. </div><br> <div> If further support is required click here to enter order id/request id, name and mobile number separated by commas. Our customer support team will reach out to you </div>",
              "support": true,
             "email": "medsupport@medibuddy.in"
          },
          {
           "id": "meds_order_status_5",
           "title": "I entered a wrong address",
           "type": "static",
           "htmlString": true,
           "desc": "<div> If your order is yet to be confirmed by retailer, please cancel your order and place a new order with the correct details. </div> <br> <div> If your order is confirmed by retailer, click below to enter order id/request id, name and mobile number separated by commas. Our customer support team will reach out to you </div>",
           "support": true,
           "email": "medsupport@medibuddy.in"
          },
          {
            "id": "meds_order_status_6",
            "title": "Medicine is out of stock",
            "type": "static",
            "htmlString": true,
            "desc": "<div> Dear Customer, We are working hard to stock up the items. Please check in some timeFconfirmed </div>",
            "support": true,
            "email": "medsupport@medibuddy.in"
           },
           {
            "id": "meds_order_status_7",
            "title": "Is my order confirmed",
            "type": "static",
            "htmlString": true,
            "desc": "<div> Dear Customer, Please check order tracking to know the status of the order. </div>",
            "support": true,
            "email": "medsupport@medibuddy.in"
           },
          {
            "id": "meds_order_status_8",
            "title": "I missed the order confirmation call. Kindly arrange another call",
            "type": "static",
            "htmlString": true,
            "desc": "<div> If you had ordered by searching for medicines and adding to cart, your order does not need a confirmation call. Our agent will call only if there is an issue with your order. </div> <br> <div> If you had ordered without searching for medicines or by directly uploading prescription, click below to enter order id/request id, name and mobile number separated by commas. Our customer support team will reach out to you </div>",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
          {
            "id": "meds_order_status_9",
            "title": "I need to know the status of my order",
            "type": "static",
            "htmlString": true,
            "desc": "You can track your order status under <b>Your Orders</b> in the medicine section on the app."
          },
  
          {
            "id": "meds_order_status_10",
            "title": "Other",
            "type": "static",
            "desc": "Contact our team",
            "support": true,
            "email": "medsupport@medibuddy.in"
          }
        ]
      },
      {
        "id": "meds_cancellation",
        "title": "Cancellation Related",
        "type": "dynamic",
        "options": [
      
 //    {
 //      "id": "meds_cancellation_2",
 //      "title": "My order is delayed",
 //      "type": "static",
 //      "htmlString": true,
 //      "desc": "<div> Your delivery might take longer than usual because of surge in demand, supply shortages and lockdown across cities. </div><br> <div><b> Home Delivery:</b> +1-2 days additional for delivery </div> <div> <b>Store pick-up: </b> 12-24 hours additional for your order to be ready </div> <div> <b>Courier: </b> +2-3 days additional for delivery </div><br> <div> If further support is required click below to enter order id/request id, name and mobile number separated by commas. Our customer support team will reach out to you </div>",
 //      "support": true,
 //      "email": "medsupport@medibuddy.in"
 //    },
 //    {
 //      "id": "meds_cancellation_4",
 //      "title": "I wanted to buy medicine under cashless",
 //      "type": "static",
 //      "htmlString": true,
 //      "desc": "<div>Kindly ensure medicines in your order are eligible for cashless benefits. Please look for the below labels in medicine search while placing an order,</div><br><div><b>Cashless:</b> </div><div> Medicine is available under cashless benefits </div> <br> <div><b> Policy Check Required:</b> </div> <div> Our team will do an eligibility check & approve eligible medicines for cashless benefits </div> <br> <div><b> Cash on Delivery / No label:</b> </div> <div> Medicine is not available under cashless benefits </div> <br>"
 //    },
 //    {
 //      "id": "meds_cancellation_6",
 //      "title": "I placed the ordered by mistake",
 //      "type": "static",
 //      "htmlString": true,
 //      "desc": "<div> Cancellation is allowed only until your order is confirmed by our retailer. You can cancel your order from the track order section in our website or application <a href='https://www.medibuddy.in/?service=medicine' style='color: #2199FE'> https://www.medibuddy.in/?service=medicine. </a> Your order can no longer be cancelled/modified by you once retailer confirms your order. </div><br> <div> If further support is required click here to enter order id/request id, name and mobile number separated by commas. Our customer support team will reach out to you </div>",
 //      "support": true,
 //      "email": "medsupport@medibuddy.in"
 //    },
 //    {
 //      "id": "meds_cancellation_8",
 //     "title": "I entered a wrong address",
 //      "type": "static",
 //      "htmlString": true,
 //      "desc": "<div> If your order is yet to be confirmed by retailer, please cancel your order and place a new order with the correct details. </div> <br> <div> If your order is confirmed by retailer, click below to enter order id/request id, name and mobile number separated by commas. Our customer support team will reach out to you </div>",
 //    "support": true,
 //   "email": "medsupport@medibuddy.in"
 //  },
          
         
          {
            "id": "meds_cancellation_2",
            "title": "Pharmacy Cancelled my order",
            "type": "static",
            "htmlString": true,
            "desc": "<div> Dear Customer, Order confirmation is based on the stock availability and the serviciability of your pincode. Our pharmacy partner can cancel orders based on these .</div>",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
          {
            "id": "meds_cancellation_3",
            "title": "I want to cancel my order ",
            "type": "static",
            "htmlString": true,
            "desc": "<div> Dear Customer, Once order is confirmed it cannot be cancelled. You can cancel your order at the time of delivery .</div>",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
          {
            "id": "meds_cancellation_4",
            "title": "I need to know the cancellation reason",
            "type": "static",
            "htmlString": true,
            "desc": "<div> Please check your email/sms for the reason of cancellation.</div>",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
          {
            "id": "meds_cancellation_support",
            "title": "Support",
            "type": "static",
            "desc": "Contact our team",
            "support": true,
            "email": "medsupport@medibuddy.in"
          }
        ]
      },
      {
        "id": "meds_payment",
        "title": "Payment or Wallet Related",
        "type": "dynamic",
        "options": [
          {
            "id": "meds_payment_2",
            "title": "Cashless medicine placed as Cash on Delivery",
            "type": "static",
            "htmlString": true,
            "desc": "<div>Kindly ensure medicines in your order are eligible for cashless benefits. Please look for the below labels in medicine search while placing an order,</div><br><div><b>Cashless:</b> </div><div> Medicine is available under cashless benefits </div> <br> <div><b> Policy Check Required:</b> </div> <div> Our team will do an eligibility check & approve eligible medicines for cashless benefits </div> <br> <div><b> Cash on Delivery / No label:</b> </div> <div> Medicine is not available under cashless benefits </div> <br>"
          },
          {
            "id": "meds_payment_4",
            "title": "Discount not applied",
            "type": "static",
            "desc": "To initiate your refund, please share the order details and raise a refund request with our customer support team by entering details below and submit",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
          {
            "id": "meds_payment_5",
            "title": "Haven't received the refund amount",
            "type": "static",
            "htmlString": true,
            "desc": "<div>To initiate your refund, please share the order details and raise a refund request with our customer support team by entering details below</div> <br> <div> If refund is already initiated, please note it will take 4-7 working days for the amount to reflect in your account </div> ",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
          {
            "id": "meds_payment_6",
            "title": "Final order amount is less than amount paid while placing the order",
            "type": "static",
            "desc": "To initiate your refund, please share the order details and raise a refund request with our customer support team by entering details below",
            "support": true,
            "email": "medsupport@medibuddy.in"
          },
          {
            "id": "meds_payment_7",
            "title": "Other",
            "type": "static",
            "desc": "Contact our team",
            "support": true,
            "email": "medsupport@medibuddy.in"
          }
        ]
      },
      {
        "id": "meds_support",
        "title": "Support",
        "type": "static",
        "desc": "Please, write your query to us",
        "support": true,
        "mobile": "9999991555",
        "email": "medsupport@medibuddy.in"
      },
    ]
  },
  {
    "id": "Consults",
    "title": "Consultation",
    "type": "dynamic",
    "options": [
      {
        "id" : "online consultation",
        "title": "Online Consultation",
        "type": "dynamic",
        "options": [
          {
            "id": "general",
            "title": "General",
            "type": "dynamic",
            "options": [
              {
                "id": "book_consultation_non_gold",
                "title": "How can I book a consultation (Audio/Video)? (Non gold user)",
                "type": "static",
                "htmlString": true,
                "desc": `
                    <div>
                      <div style='margin-bottom: 4px'> Dear User, to book a consultation with a specialist doctor, kindly login to the MediBuddy application & click "Talk to Doctor" .</div>
                      <ul>
                        <li> Now click on "Who is the Patient? (Myself / Wife / Father etc..)</li>
                        <li> Enter Patient Age </li>
                        <li> Select Gender </li>
                        <li> Confirm the contact number on which you would like the doctor to call.</li>
                        <li> Select your health problem (Department) </li>
                      </ul>
                      <div style='margin-bottom: 4px'>Continue to enter patients health issue, on the "Tell us the symptom" section. </div>
                      <ul>
                        <li> Click on "Start Consultation".</li>
                        <li>Select type of consultation "Video / Phone call"</li>
                      </ul>
                      <div style='margin-bottom: 4px'>Kindly continue to respond to Doctor's Assistant Questions & Make the payment online to get a call from the Doctor. </div>
                      <div style='margin-bottom: 4px'>Thank You! </div>
                      <iframe src="https://faqconsults.docsapp.in/Video+1.mp4" width="100%" height="400" allow="autoplay" loading="lazy" ></iframe>
                    </div>`
              },
              {
                "id": "book_consultation_gold",
                "title": "How can I book a consultation (Audio/Video)? (Gold/ Corporate user)",
                "type": "static",
                "htmlString": true,
                "desc": `
                    <div>
                      <div style='margin-bottom: 4px'> Dear User, to book a consultation with a specialist doctor, kindly login to the MediBuddy application & click "Talk to Doctor" .</div>
                      <ul>
                        <li> Now click on "Who is the Patient? (Myself / Wife / Father etc..)</li>
                        <li> Enter Patient Age </li>
                        <li> Select Gender </li>
                        <li> Confirm the contact number on which you would like the doctor to call.</li>
                        <li> Select your health problem (Department) </li>
                      </ul>
                      <div style='margin-bottom: 4px'>Continue to enter patients health issue, on the "Tell us the symptom" section. </div>
                      <ul>
                        <li> Click on "Start Consultation".</li>
                        <li>Select type of consultation "Video / Phone call"</li>
                      </ul>
                      <div style='margin-bottom: 4px'>Thank You! </div>
                      <iframe src="https://faqconsults.docsapp.in/Video+1.mp4" width="100%" height="400" allow="autoplay", loading='lazy'></iframe>
                    </div>`
              },
              {
                "id": "doctor_choice",
                "title": "Can I chose a doctor of my choice in particular?",
                "type": "static",
                "htmlString": true,
                "desc": `
                  <div style='margin-bottom: 4px'> Dear User, you can only choose a doctor of a specific gender or who speak specific languages. We have a team of carefully selected and personally verified top doctors from the Country who are all MD's with 5+ years of experience on our platform, from across India. Each doctor has received extensive training to consult with you over the app.</div>
                  <div style='margin-bottom: 4px'>Thank You! </div>
                  `
              },
              {
                "id": "doctor_choice_gender",
                "title": "Can I choose a doctor of a specific gender or language?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'> Dear User, Yes, you can choose a doctor of a specific gender or language.</div>
                <div style='margin-bottom: 4px'>Kindly follow the below steps :- </div>
                <ul>
                  <li> A default Doctor is assigned to you</li>
                  <li> Click on "Want to talk to a different Doctor" below the previous doctor's profile </li>
                  <li> You can Select the Gender or Language of the Doctor  </li>
                  <li> Click on Submit</li>
                </ul>
                <div style='margin-bottom: 4px'>Thank You! </div>
                <iframe src="https://faqconsults.docsapp.in/Video+2.mp4" width="100%" height="400" allow="autoplay" loading="lazy"></iframe>
              </div>`
              },
              {
                "id": "doctor_change",
                "title": "I want to change my doctor",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Issue with the current doctor:- </div>
                <ul>
                  <li> We are sorry you had an issue. (redirect to the 'new doctor card') </li>
                </ul>
                <div style='margin-bottom: 4px'>I want another doctor:- </div>
                <ul>
                  <li> We have given you the best experienced doctor available at this moment. Incase you still want to contact a different doctor (redirect to the 'new doctor card') </li>
                </ul>
              </div>`
              },
              {
                "id": "doctor_call_when",
                "title": "When will the Doctor call me?",
                "type": "static",
                "htmlString": true,
                "desc": `
                  <div style='margin-bottom: 4px'> Doctor usually calls you in 30 minutes. Right now, Due to high demand with an increase in covid cases, doctors can take upto 3 hrs to call you. </div>
                  `
              },
              {
                "id": "general_support_1",
                "title": "Doctor hasn't called me even after 3 hours?",
                "type": "static",
                "htmlString": true,
                "support": true,
                "email": "hello@medibuddy.in",
                "desc": `
                  <div style='margin-bottom: 4px'> Dear User,<br>
                  Sorry for the inconvenience caused. This is highly unusual. Please get in touch with our customer support.</div>
                  `
              },
              {
                "id": "follow_up_validity",
                "title": "How long is the follow up valid?",
                "type": "static",
                "htmlString": true,
                "desc": `
                  <div style='margin-bottom: 4px'> Dear User,<br>
                  You can take one free follow-up with the doctor within 3 days of the initial consultation date.<br>
                  Kindly note that the user has to make the necessary payments if the followup is requested after 3 days of the consultation period. 
                  </div>
                  `
              },
              {
                "id": "download_prescription",
                "title": "Where can I Download the Rx/Prescription?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, to View or Download the Prescription:- </div>
                <ul>
                  <li> Click on the "Menu" top left corner of the application </li>
                  <li> Click on 4th option "My Medical Records" </li>
                  <li> Select "Prescription / Rx" - Download </li>
                </ul>
                <div style='margin-bottom: 4px'>You can also view your Prescription given by the doctor on your consultation window.</div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                <iframe src="https://faqconsults.docsapp.in/Video+3.mp4" width="100%" height="400" allow="autoplay" loading="lazy"></iframe>
              </div>`
              },
              {
                "id": "invoice",
                "title": "Where can I find the invoice?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, to View or Download the invoice:- </div>
                <ul>
                  <li> Click on the "Menu" top left corner of the application </li>
                  <li> Click on 4th option "My Medical Records" </li>
                  <li> Select "Invoice / Bills" - Download </li>
                </ul>
                <div style='margin-bottom: 4px'>Thank You! </div>
                <iframe src="https://faqconsults.docsapp.in/Video+4.mp4" width="100%" height="400" allow="autoplay" loading="lazy"></iframe>
                </div>`
              },
              {
                "id": "general_support_2",
                "title": "Can I consult with doctors online for a medical certificate?",
                "type": "static",
                "htmlString": true,
                "support": true,
                "email": "hello@medibuddy.in",
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, at this moment, we will not be able to provide any kind of medical certificates to customers without physical examination, according to government guidelines. </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "past_consults",
                "title": "Where can I see my past/upcoming consults?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, on the main home screen of the MediBuddy application you will see the option of "Past Consultations" you can click on "View All" to check your consultation history. </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                <iframe src="https://faqconsults.docsapp.in/Video+5.mp4" width="100%" height="400" allow="autoplay" loading="lazy"></iframe>
                </div>`
              },
              {
                "id": "services_abroad",
                "title": "Can I avail these services from abroad?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, Yes these services can be availed from abroad, do note that calls will happen only via Internet calling options available on the MediBuddy App.</div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
           //   {
           //     "id": "consultation_insurance_claim",
           //     "title": "Can I use the consultation invoice for insurance claims?",
           //     "type": "static",
           //     "htmlString": true,
           //     "desc": `
           //     <div>
           //     <div style='margin-bottom: 4px'>Dear User, Regarding raising a claim with an online consultation invoice, kindly reach out to your Corporate team. As it depends upon the norms and policies of the company.</div>
           //     <div style='margin-bottom: 4px'>Thank You! </div>
           //     </div>`
           //   },
              {
                "id": "cost",
                "title": "How much does it cost?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Our consultation fees are much more reasonable than clinic rates. You can save up to 60% by consulting India's top doctors through Medibuddy. Adding the savings of NOT travelling in traffic and waiting for appointments, Medibuddy is a deal you cannot resist!</div>
                </div>`
              },
              {
                "id": "specialists",
                "title": "Do you have specialists (Gynecologists, Psychologists, etc.)?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Yes, we strive hard to ensure that you get to discuss your concern with a MD or MS who specializes in that area. For eg: If you have childcare concerns we connect you with a Pediatrician , for Women’s issues we have highly experienced Gynecologists etc.<br>
                These are some of the specialties we have:
                </div>
                <ul>
                  <li> General Physician </li>
                  <li> Sexology </li>
                  <li> Stress and Mental Health </li>
                  <li> Dermatology </li>
                  <li> Gynaecology </li>
                  <li> Weight Management </li>
                  <li> Pediatrics </li>
                  <li> Gastroenterology </li>
                  <li> Neurology </li>
                  <li> Orthopedics </li>
                  <li> Diabetes Consult </li>
                  <li> Cardiology </li>
                  <li> Oncology </li>
                </ul>
                </div>`
              },
              {
                "id": "consult_in_emergency",
                "title": "Can I take online consultation for emergencies? ",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>No. This app is for primary care- the kind of problems that your family doctor can help you with. Doctors on this app can only help you with consultation based issues that can be shared in the form of messages, pictures and medical reports. In case of a life- threatening emergency, please call an ambulance or head to a nearby hospital. </div>
                </div>`
              },
              {
                "id": "medicines_prescribed",
                "title": "What medicines do you prescribe?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>We mostly have allopathic doctors on our team. We do not advise on homeopathy, Ayurveda or any other form of treatment. We suggest over- the- counter (OTC) medicines and home remedies, just as your family doctor would. The same can be ordered on our app and will be delivered at your doorstep by our medicine delivery partners. </div>
                </div>`
              },
              {
                "id": "offline_appointment",
                "title": "Get offline appointment with a specific doctor",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Book appointments with Top Doctors in your city' section of our app to avail offline services. </div>
                </div>`
              },
            ]
          },
          {
            "id": "consultations",
            "title": "Consultations",
            "type": "dynamic",
            "options": [
              {
                "id": "reschedule",
                "title": "How do I reschedule?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'> Dear User,If you want a consultation with the same doctor at another time(within the next 3 days):" .</div>
                <ul>
                  <li> You can check with the doctor on chat or call about mutually convinient slots within 3 days of booking</li>
                  <li> Accordingy, you can inform the doctor to reschedule the consultation for the discussed slot</li>
                  <li> Once you get the details of this confirmed slot then you can send a message on the chat window, so that the doctor can acll you during the agredd slot </li>
                  <li> Do note that since this is a rescheduled slot, doctor will not call unless you drop a messsage on a chat</li>
                </ul>
                <div style='margin-bottom: 4px'>If you are fine with the other Doctors who are available at your convenience(only for Gold/Package users) </div>
                <ul>
                  <li> please go to 'Past Consultations' on the home screen</li>
                  <li>long press this particular consult and delete consultation</li>
                  <li>book another consultation whenever convenient</li>
                  <li>Please note that non-package/non-gold users will be charged for bothj consultations</li>
                  </ul>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "cancel_consultation",
                "title": "How do I cancel my consultation?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, you can Cancel or Delete the consultation Click on "View All" & Long press on the particular consultation.<br>   
                Do note that any refunds in such scenarios cannot be processed.     
                </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                <iframe src="https://faqconsults.docsapp.in/Video+6.mp4" width="100%" height="400" allow="autoplay"  loading="lazy"></iframe>
                </div>`
              },
              {
                "id": "delete_image_consultation_history",
                "title": "I want to delete image or consultation history",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, you can long press the image which you want to delete and confirm the option.<br>   
                By Long pressing on the consultation window, you can delete a particular consultations. Do note that any refunds in such scenarios cannot be processed. 
                </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "consultations_support_1",
                "title": "Can I speak with the same doctor again?",
                "type": "static",
                "htmlString": true,
                "support": true,
                "email": "hello@medibuddy.in",
                "desc": `
                <div>
                <div style='margin-bottom: 4px'> Dear User, follow the below steps to book a consultation with a particular Doctor :- </div>
                <ul>
                  <li> Open the consultation window of the doctor which you want to consult, and click on "Consult Again" Button. </li>
                  <li> If you want to create a new window with same doctor then, Create a new consultation then click on "Change doctor" and select the particular Doctor's profile.</li>
                  <li> - If you are not getting your doctor's name in the list then, kindly reach out to the Customer Support.</li>
                </ul>
                <div style='margin-bottom: 4px'>Thank You! </div>
                <iframe src="https://faqconsults.docsapp.in/Video+7.mp4" width="100%" height="400" allow="autoplay" loading="lazy"></iframe>
              </div>`
              },
              {
                "id": "follow_up_help",
                "title": "I want help regarding followups. ",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, post completing a call with the doctor the chat window will be closed after 1 hour. Request a follow up by clicking on "Consult Again". </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                <iframe src="https://faqconsults.docsapp.in/Video+7.mp4" width="100%" height="400" allow="autoplay" loading="lazy"></iframe>
                </div>`
              },
              {
                "id": "consultations_support_2",
                "title": "Unable to book consultation with a particular doctor you have previously contacted with",
                "type": "static",
                "htmlString": true,
                "support": true,
                "email": "hello@medibuddy.in",
                "desc": `
                <div>
                <div style='margin-bottom: 4px'> Dear User, follow the below steps to book a consultation with a particular Doctor :- </div>
                <ul>
                  <li> Open the consultation window of the doctor which you want to consult, and click on "Consult Again" Button. </li>
                  <li> If you want to create a new window with same doctor then, Create a new consultation then click on "Change doctor" and select the particular Doctor's profile.</li>
                  <li> If you are not getting your doctor's name in the list then, kindly reach out to the Customer Support.</li>
                </ul>
                <div style='margin-bottom: 4px'>Thank You! </div>
                <iframe src="https://faqconsults.docsapp.in/Video+7.mp4" width="100%" height="400" allow="autoplay" loading="lazy"></iframe>
              </div>`
              },
              {
                "id": "no_call_notification",
                "title": "Did not receive notification of the call",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'> Dear User, to receive call notification kindly follow the below steps :- </div>
                <ul>
                  <li> Go to Mobile Settings </li>
                  <li> Click on Application </li>
                  <li> Select MediBuddy App </li>
                  <li> Enable the notification </li>
                </ul>
                <div style='margin-bottom: 4px'>Thank You! </div>
              </div>`
              },
              {
                "id": "consultation_recording",
                "title": "Where can I find the recording of the consultation?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, we currently do not provide any recordings of the consultation.</div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "not_right_speciality",
                "title": "This is not the right speciality",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, you can choose the speciality while creating a consultation, if still you are not sure then click on "I don't know" the doctor will guide you to the appropriate department.</div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              }
            ]
          },
          {
            "id": "doc_not_responding",
            "title": "Doctor not Responding",
            "type": "dynamic",
            "options": [
              {
                "id": "no_doc_response_paid",
                "title": "Doctor is not responsing to my paid chat or follow up chat ",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, to get a good experience with doctor consultation, kindly make yourself free for 3 to 4 mins for call consultation or you can ask your doctor to reschedule the consultaion when you will be free to talk with doctor.</div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "not_single_response",
                "title": "Not a single response ",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, we apologize for the inconvinence caused, kindly wait for 30 mins for the doctor to call you.</div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "docNotResponding_support_1",
                "title": "Doctor replied initially but stopped responding now ",
                "type": "static",
                "htmlString": true,
                "support": true,
                "email": "hello@medibuddy.in",
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, if the Doctor has responded in chat then kindly wait for 5 to 10 mins, as the Doctor might be busy checking your details or reports.<br>
                If the Doctor has failed to call you even after 10 minutes since the last contact, kindly reach out to customer support.
                </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "docNotResponding_support_2",
                "title": "Doctor ended the consultation, why? ",
                "type": "static",
                "htmlString": true,
                "support": true,
                "email": "hello@medibuddy.in",
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, if the consultation has ended without you having received a call from Doctor, there might have been a technical issue. Kindly reach out to customer support. </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "no_ask_more_questions",
                "title": "Unable to ask further questions.",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, after completing a call with the doctor the chat window will be closed after 1 hour. Request a follow up by clicking on "Consult Again". </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "package",
                "title": "No response after chat(Activate Package)",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User , You can buy Gold Membership for 1 year in which you will get benefits like:-  </div>
                <ul>
                  <li> 5 free consultations in 7 days in any speciality. </li>
                  <li> With each consultation you will get 1 free followup from the Dr. </li>
                  <li> Upto 50 % discount in booking lab test. </li>
                  <li> Upto 15% discount in ordering medicines </li>
                </ul>
                </div>`
              },
              {
                "id": "paid",
                "title": "No response after chat(make payment)",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, since this is a paid consultation, kindly make the requisite payment, so that we can begin your consultation with the Doctor.</div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              }
            ]
          },
          {
            "id": "prescription",
            "title": "Prescription",
            "type": "dynamic",
            "options": [
              {
                "id": "prescription_support_1",
                "title": "There are errors in my Rx",
                "type": "static",
                "htmlString": true,
                "support": true,
                "email": "hello@medibuddy.in",
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, for any modifications in the Rx regarding Name, Date, Age or Gender in Rx Kindly reach out to your doctor within the free follow-up period. Kindly wait for a few minutes for the Doctor to respond as he might be occupied with other cases. In the event, he/she doesn't respond, please reach out to our customer support team.</div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "not_received_prescription",
                "title": "I haven't received prescription for my consultation",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, to View or Download the Prescription :- </div>
                <ul>
                  <li> Click on the "Hamburger Menu" top left corner of the application </li>
                  <li> Click on 4th option "My Medical Records" </li>
                  <li> Select "Prescription / Rx"- Download </li>
                </ul>
                <div style='margin-bottom: 4px'> You can also view your Prescription given by the doctor on your consultation window. </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              }
            ]
          },
          {
            "id": "doc_issues",
            "title": "Doctor Issues",
            "type": "dynamic",
            "options": [
              {
                "id": "doctor_behaviour",
                "title": "I have a problem with the doctor's behaviour ",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, we apologize for the inconvenience caused.</div>
                <ul>
                  <li> Kindly go to Help & Support option in App </li>
                  <li> Click on Consultation </li>
                  <li> Click on Support </li>
                  <li> Enter your Email Id, Phone number & Concern </li>
                  <li> Click on Submit </li>
                </ul>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "doc_qualifications",
                "title": "I want details about the Dr's qualifications ",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, you can click on "View Profile" on the consultation window for your doctor, to check Specialization / Qualification / Registration & more.</div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                <iframe src="https://faqconsults.docsapp.in/Video+8.mp4" width="100%" height="400" allow="autoplay" loading="lazy"></iframe>
                </div>`
              },
              {
                "id": "disagree_with_treatment",
                "title": "I disagree with the treatment ",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear User, we apologize for the inconvenience caused. We have a team of carefully selected and personally verified top doctors from the Country who are all MD's with 5+ years of experience on our platform, from across India. Each doctor has received extensive training to consult you over the app.</div>
                <ul>
                  <li> Kindly go to Help & Support option in App </li>
                  <li> Click on Consultation </li>
                  <li> Click on Support </li>
                  <li> Enter your Email Id, Phone number & Concern </li>
                  <li> Click on Submit </li>
                </ul>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              }
            ]
          },
          {
            "id": "payment",
            "title": "Payment",
            "type": "dynamic",
            "options": [
              {
                "id": "payment_support_1",
                "title": "Haven't received my refund",
                "type": "static",
                "htmlString": true,
                "support": true,
                "email": "hello@medibuddy.in",
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear user, the refund process takes 7 to 10 business days. However we can refund it in your MediBuddy wallet in within 1 hour. Kindly reach out to customer support if you need us to refund it to your wallet. </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              },
              {
                "id": "discount_coupons",
                "title": "Discount coupons? ",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <div style='margin-bottom: 4px'>Dear user, You will receive promotional emails on the registered email with MediBuddy. </div>
                <div style='margin-bottom: 4px'>Thank You! </div>
                </div>`
              }
            ]
          },
        ]
      },
      {
        "id" : "offline consultation",
        "title" : "Offline Consultation",
        "type" : "dynamic",
        "options" : [
          {
            "id" : "offlineConsultationBooking",
            "title" : "Booking",
            "type": "dynamic",
            "options" : [
              {
                "id": "book_offline_consultation_opd",
                "title": "How should I book an appointment for OPD Consultation?",
                "type": "static",
                "htmlString": true,
                "desc": `<div>
                <ul>
                  <li> Use your ‘Current Location’ or search your locality or your preferred clinic/hospital.</li>
                  <li> Select a doctor and click on the ‘Book Visit’ button. </li>
                  <li> Select your preferred date and time and click on the ‘Confirm’ button.</li>
                  <li> Choose the beneficiary and update the name, DOB, gender and contact details.</li>
                  <li> Proceed to pay for the consultation (using corporate/insurance sponsored wallet or through UPI/Debit card/Credit card, depending on policy coverage) </li>
                  <li> Booking confirmed.</li>
                </ul>
              </div>`
              },
              {
                "id": "book_offline_consultation_advance",
                "title": "How many days in advance do I need to book an appointment?",
                "type": "static",
                "htmlString": true,
                "desc": `
                <div>
                <ul>
                  <li> You can book appointments for next day (before 2pm, today) or upto 7 days in advance (slots available from T+1 day to T+7 days depending on doctor availability)</li>
                  <li> Also, you can book the next day's appointment before 2PM, today. </li>
                  <li> Same day appointments are not available.</li>
                </ul>
              </div>`
              }
            ]
          },
          {
            "id" : "offlineConsultationReschedule",
            "title" : "Reschedule/Cancellation",
            "type": "dynamic",
            "options" : [
              {
                "id": "reschedule_offline_consultation",
                "title": "Can an appointment be rescheduled?",
                "type": "static",
                "htmlString": true,
                "desc": `<div>
                <div style='margin-bottom: 4px'>Yes, Please call on our customer support number : <b>+91-9999991555</b> to reschedule your appointment. </div>
                <div style='margin-bottom: 4px'>Alternatively, you can email us on <b>hello@medibuddy.com</b> with a request to reschedule your appointment. Please mention the date and time for rescheduling the appointment.</div>
                <div style='margin-bottom: 4px'>
                You will receive confirmation on your reschedule request within 4 hours of request. 
                </div>
                <ul>
                  <li> This SLA is calculated starting from the customer’s first response time.</li>
                  <li>If the request is placed between 9 am to 5pm, anything beyond 5pm would be responded by first half of the next day (due to network/clinic availability)</li>
                </ul>
                </div>`
              },
              {
                "id": "reschedule_offline_consultation_appointment_cancelled",
                "title": "Can an appointment be cancelled?",
                "type": "static",
                "htmlString": true,
                "desc": `<div>
                <div style='margin-bottom: 4px'>Yes,</div>
                <ul>
                  <li> Select the Profile icon on the top-right corner.</li>
                  <li>Select Track orders.</li>
                  <li>Click on ‘Cancel Appointment’</li>
                </ul>
                <div style='margin-bottom: 4px'>Cancellation requests can be placed through mail/call to our customer support as well,</div>
                <div style='margin-bottom: 4px'>Mail ID:  <b>hello@medibuddy.com</b>,</div>
                <div style='margin-bottom: 4px'> Phone Number: <b>+91-9999991555</b>.</div>
                </div>`
              }
            ]
          },
          {
            "id" : "offlineConsultationRefund",
            "title" : "Refund",
            "type": "dynamic",
            "options" : [
              {
                "id": "offline_consultation_refund",
                "title": "Will I be refunded for not making an appearance on the appointment date?",
                "type": "static",
                "htmlString": true,
                "desc": `<div>
                <div style='margin-bottom: 4px'>Yes,</div>
                <ul>
                  <li> Payments made through a payment gateway or a wallet will be eligible for refund.</li>
                  <li>Refunds will be initiated within 24 hours after re-confirmation of attendance with the network partner.</li>
                  <li>Payment gateway refunds once initiated, will take between 5-7 days to reflect in your bank/ card account.</li>
                  <li>Wallet refunds once initiated will be completed/processed within 24 hours.</li>
                </ul>
                </div>`
              }
            ]
          }
        ]
      },
      {
        "id" : "dental procedures",
        "title": "Dental Procedures",
        "type" : "dynamic",
        "options" : [
          {       
          "id" : "Dental_Procedures_Booking",
          "title" : "Booking",
          "type": "dynamic",
          "options": [
            {
              "id": "Dental_Procedures_Booking_process",
              "title": "How can I book a dental procedure, if the doctor has prescribed me a procedure?",
              "type": "static",
              "htmlString": true,
              "desc": `<div>
              <ul>
                <li>If the doctor has recommended a procedure in your dental consultation, we will reach out to you over call to clarify on cashless coverage for the procedure basis your policy and we will also help you book the appointment for the procedure.</li>
                <li>You can also call our dental care number <b>+91-9999991555</b> to book your procedure. Please note, all dental procedures require a consultation, case summary/treatment plan (& x-ray if applicable)</li>
              </ul>
              </div>` 
            },
          ]
          },
          {
          "id" : "Dental_Procedures_Pricing",
          "title" : "Pricing/Payment",
          "type": "dynamic", 
          "options" : [
            {
              "id": "Dental_Procedures_treatment_cost",
              "title": "How much will the dental treatment cost?",
              "type": "static",
              "htmlString": true,
              "desc": `<div>
              <ul>
                <li>Fee will vary depending upon the treatment/procedure option. We believe in complete fee transparency and will provide a treatment plan and the cost before the treatment commences.</li>
                <li>We have standard pricing with all our dental provider network.</li>
              </ul>
              </div>` 
            },
            {
              "id": "Dental_Procedures_payment_mode",
              "title": "How can I pay for my Dental Treatment?",
              "type": "static",
              "htmlString": true,
              "desc": `<div>
              <div style='margin-bottom: 4px'>Corporate employees can pay using their sponsored/insurance wallet as per their policy coverage and all other customers can pay through UPI/Debit/Credit card.</div>
              </div>`  
            },
            {
              "id": "Dental_Procedures_payment_exceeds_wallet_amount",
              "title": "What if my dental treatment cost exceeds my sponsored/ insurance wallet amount?",
              "type": "static",
              "htmlString": true,
              "desc": `<div>
              <div style='margin-bottom: 4px'>You can pay the remaining/balance amount at the centre after completion of your procedure. You will be intimated about this amount (if any) prior to your appointment</div>
              </div>`  
            }
          ]
          },
          {
            "id": "Dental_Procedures_safety",
            "title": "Safety",
            "type": "dynamic",
            "options" : [
              {
              "id": "Dental_Procedures_safety_measures",
              "title": "What are the safety measures adopted by our Dental Clinic Providers?",
              "type": "static",
              "htmlString": true,
              "desc": `<div>
              <ul>
              <li>Clinics have adopted world-class measures to ensure the safety of patients. We follow a zero-compromise policy when it comes to safety & hygiene. This ensures a completely sterile & hygienic environment.</li>
              <li>We also have an audit mechanism for each network provider,</li>
              <ul>
              <li>Customer feedback,</li>
              <li>Mystery audits,</li>
              <li>Initial onboarding orientation, etc.</li>
              </ul>
            </ul>
              </div>`   
              }
            ]
          }

        ]
      },
      {
        "id": "consultation_support",
        "title": "Support",
        "type": "static",
        "htmlString": true,
        "support": true,
        "mobile": "9999991555",
        "email": "hello@medibuddy.in",
        "desc": "<div style='margin-bottom: 4px'> <div>  Please, write your query to us </div></div>"
      },
    ]
  },
  {
    "id": "insured",
    "title": "Insurance",
    "type": "dynamic",
    "options": [
      {
        "id": "insured_3",
        "title": "What are the types of hospitalization expenses typically covered by my policy?",
        "type": "static",
        "htmlString": true,
        "desc": "<div><div style='margin-bottom: 4px'> Apart from hospitalization expenses, your health insurance policy may cover several other expenses (subject to terms and caps). Expenses associated with hospitalization can typically be classified as follows: </div> <ul><li> <b> Pre-hospitalization expenses: </b> When you’re unwell, you will most likely consult a physician first, who gets relevant investigations done before advising hospitalization. Such medical expenses incurred before hospitalization are called pre-hospitalization expenses. </li><li> <b> Hospitalization expenses: </b> All expenses incurred as part of your hospital stay as an in-patient can be termed as hospitalization expenses. </li><li> <b> Post-hospitalization expenses: </b> Some part of your treatment may extend beyond your hospitalization. It may involve follow-up visits to the doctor, prescription medication, further investigations, etc. Such medical expenses are called post-hospitalization expenses. </li> <li> <b> Domiciliary hospitalization expenses: </b> This typically includes expenses related to domiciliary hospitalization – at-home medical treatment (for reasons such as inability to move the patient) for more than three days for cases which in the normal course would require hospitalization. The exact circumstances / terms / exclusions related to domiciliary hospitalization would be spelt out in your policy document. </li></ul></div>"
      },
      {
        "id": "insured_11",
        "title": "How do I raise a reimbursement claim?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> <div style='margin-bottom: 4px'>Although cashless hospitalization facility is available at the Medi Assist network of hospitals, you may sometimes need to use hospitals that are not in the Medi Assist network. Reimbursement claims may be filed in the following circumstances: </div><ul> <li>Hospitalization at a non-network hospital </li> <li>Post- and pre-hospitalization expenses / domiciliary expenses that are not covered by your policy </li> <li>Denial of preauthorization for specific reasons. </li></ul> <div style='margin-bottom: 4px'>In such cases, you can claim a reimbursement after discharge. </div><ul> <li>Intimate us about your impending claim. </li> <li> Present your Medi Assist e-card at the hospital during admission along with any other legally accepted identity card. \n\n Note: You can log into your Medi Assist online portal and click Generate E-Card to instantly generate and print out an e-card for any beneficiary.</li> <li> Submit your reimbursement claim online within 7 days from date of discharge.</li> <li> Send the original documents to Medi Assist within 30 days from the date of discharge from hospital. </li> <li>Track your claim in real-time. </li> <li> The medical team at Medi Assist processes the claim: <ul> <li>In case of approval, the amount is reimbursed either via NEFT or cheque (sent to the address mentioned in your policy). </li> <li>In case your claim is denied, the denial letter is sent to you by courier / post / e-mail quoting the reason for denial of your claim. </li> </ul> </li>  </ul></div>"
      },
      {
        "id": "insured_2",
        "title": "Tell me about hospitalization",
        "type": "static",
        "htmlString": true,
        "desc": "<div><div style='margin-bottom: 4px'> Hospitalization can be of two types: </div> <ul><li> <b> Planned Hospitalization: </b> This happens when you have ample time to plan your admission to the hospital. For example, if your doctor advises a surgery for hernia anytime in the next few weeks, you have time to plan your hospitalization. </li><li> <b> Emergency Hospitalization: </b> This happens typically in case of emergencies, such as a road traffic accident. One cannot plan for such hospitalization. </li> </ul></div>"
      },
      {
        "id": "insured_7",
        "title": "What are the different types of claims?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> <div style='margin-bottom: 4px'> Depending on the situation and your policy coverage, you can make two types of health insurance claims:</div> <ul> <li> <b>Cashless: </b>The essence of cashless hospitalization is that the insured need not make an upfront payment to the hospital at the time of admission. You may not have any out-of-pocket expenses towards hospitalization in this scenario. Cashless hospitalization can be availed only at a Medi Assist network hospital and upon approval of your pre-authorization application. </li> <li> <b> Reimbursement:</b> A reimbursement claim is one where you pay all the expenses related to the hospitalization of the insured and claim a reimbursement of your expenses after discharge. Reimbursement claims may be filed in the following circumstances: <ul><li> Hospitalization at a non-network hospital </li> <li>Post-hospitalization and pre-hospitalization expenses </li> <li>Denial of pre-authorization for cashless facility at a network hospital. </li></ul> </li> <ul> </div>"
      },
      {
        "id": "insured_5",
        "title": "Non Admissible Expenses",
        "type": "static",
        "htmlString": true,
        "desc": "<a href='https://www.medibuddy.in/assets/claimForms/non-admissible-expenses.pdf' target='_blank' >View Non Admissible Expenses</a>"
      },
      {
        "id": "insured_13",
        "title": "What is the deadline to submit a reimbursement claim?",
        "type": "static",
        "desc": "Claim intimation (with particulars relating to policy number, name of the insured person for whom the claim is made, nature of illness/injury and name and address of the attending medical practitioner/hospital/nursing home) should be submitted within 7 (seven) days from the date of hospitalisation/injury."
      },
      {
        "id": "insured_12",
        "title": "What documents must I submit for a reimbursement claim?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> <div style='margin-bottom: 4px'>The following are the documents that you would have to submit while claiming a reimbursement: </div><ul> <li> Original hospital final bill </li> <li> Original numbered receipts for payments made to the hospital </li> <li> Complete breakup of the hospital bill </li> <li> Original discharge summary </li> <li> All original investigation reports along with prescriptions </li> <li> All original medicine bills with relevant prescriptions </li> <li> Original signed claim form </li> <li> Copy of the Medi Assist ID card or current policy copy and previous years’ policy copies (if any) </li> <li> Covering letter stating your complete address, contact numbers and email address (if available). </li></ul></div>"
      },
      {
        "id": "insured_6",
        "title": "What is co-pay?",
        "type": "static",
        "desc": "- Co-pay is a percentage applied on payable amount which the policy holder has to pay at the time of discharge. The rest of the amount is paid by the insurance company."
      },
      {
        "id": "insured_9",
        "title": "What is the process for cashless hospitalization",
        "type": "static",
        "htmlString": true,
        "desc": "<div><div style='margin-bottom: 4px'>Cashless hospitalization can be availed only at Medi Assist network of hospitals. Here is the generic process to avail cashless hospitalization: </div> <ul><li> Intimate Medi Assist about your hospitalization. </li> <li> <div> Present your Medi Assist e-card at the hospital during admission along with any other legally accepted identity card. </div><div>Note: You can log into your Medi Assist online portal and click Generate E-Card to instantly generate and print out an e-card for any beneficiary. </div> </li> <li> Ensure that the hospital sends your pre-authorization form to Medi Assist.</li> <li> Track your claim in real-time using MediBuddy Online or Mobile. </li><li> Medi Assist sends the approval to the hospital. Further enhancement approvals may be issued on request, subject to terms and conditions of the policy.</li><li>In case the request cannot be approved or if the expenses are not covered by your policy, you will have to settle the hospital bill in full and subsequently raise a reimbursement claim after discharge \n Note: Denial of a preauthorization request must not be construed as denial of treatment or denial of coverage.</li> <li> After discharge, the hospital will send all the documents related to your claim to Medi Assist for settlement.</li> </ul></div>"
      },
      {
        "id": "insured_4",
        "title": "Are my medical expenses incurred before and after hospitalization covered?",
        "type": "static",
        "desc": "- Your policy may allow reimbursement of relevant medical expenses incurred during, pre- & post-hospitalization for the particular condition for which hospitalization was necessary. This is subject to the terms and limits prescribed in the policy. Please read your policy document in detail."
      },
      {
        "id": "insured_14",
        "title": "How long does it take to process the reimbursement claim and when will I receive the payment after approval of the claim?",
        "type": "static",
        "desc": "After receiving all the required documents, it takes 15 working days to process the claim and 7 working days after that for payment."
      },
      {
        "id": "insured_8",
        "title": "What is planned hospitalization?",
        "type": "static",
        "htmlString": true,
        "desc": "<div>If you are aware of the fact that you or your family members require hospitalization in the near future, you can send prior intimation to Medi Assist to plan your hospitalization. Not only does this ease the admission and pre-authorization process, but it also helps reduce your overall cost of treatment wherever possible. </div><br><div> Once Medi Assist receives the intimation, we will help you plan everything including choosing a network hospital, opting for discounted packages, if available, and planning for cashless hospitalization. </div>"
      },
      {
        "id": "insured_10",
        "title": "Is there any time limit to submit the pre-authorization request?",
        "type": "static",
        "desc": "In case of an emergency or unplanned admission, the hospital must send the pre-authorization request to Medi Assist within 24 hours from the time of admission. In case of a planned hospitalization, it is prudent to send the preauthorization request to Medi Assist at least 72 hours prior to the admission date. This will ensure a hassle-free admission procedure for you at the hospital."
      },
      {
        "id": "insured_15",
        "title": "How long does it take to process the reimbursement claim and when will I receive the payment after approval of the claim?",
        "type": "static",
        "htmlString": true,
        "desc": "<div style='margin-bottom: 4px'> There is no upper limit on the number of claims that can be raised during the policy period. However, the total cumulative claim amount cannot exceed the sum insured in the policy. </div><div>The final claim form along with the hospital receipt, bills, cash memos and the list of documents as listed in the claim form should be submitted to Medi Assist within 30 (thirty) days from the date of discharge from the hospital. </div>"
      },
      {
        "id": "insured_16",
        "title": "What happens to my sum insured after a claim is filed? And what happens if the cost exceeds my hospital insurance cover?",
        "type": "static",
        "desc": "The sum insured is reduced by the amount of the claim paid for the rest of the policy year. You have to pay the balance amount if your claim exceeds the sum insured."
      },
      {
        "id": "insured_17",
        "title": "What are shortfall documents or information required (IR), and when and where do I send them?",
        "type": "static",
        "htmlString": true,
        "desc": "<div style='margin-bottom: 4px'> Shortfall documents are raised if the documents already sent are insufficient for further validation of the existing claim. They have to be sent within 10 working days of the receipt of the e-mail notifying you of insufficient documentation. </div><div>If you are an individual policy holder, you can submit the shortfall documents to the nearest Medi Assist branch. If you are a corporate employee, just hand them over to the respective helpdesk person. </div>"
      },
      {
        "id": "insured_support",
        "title": "Support",
        "type": "static",
        "desc": "Please, write your query to us",
        "support": true,
        "mobile": "9999991555",
        "email": "hello@medibuddy.in"
      },
    ]
  },
  {
    "id": "ahc",
    "title": "Labs & Health Check",
    "type": "dynamic",
    "options": [
      {
        "id": "ahc_book_appointment",
        "title": "Book Appointment",
        "type": "static",
        "htmlString": true,
        "desc": "<div> <div style='margin-bottom: 4px'><b> How should I book an appointment for a Health Checkup? </b> </div> <div> - You can book an appointment via the following steps:</div><ul><li> Choose your city</li><li> Choose your package </li><li>Confirm tests in your package<ul><li>Centre visit: Choose a date, time and location</li><li>Home sample collection: Enter your pin code and choose a date and time</li></ul></li><li> Add additional tests if needed </li><li> Book tests for family members </li><li> Confirm your contact information </li><li> Appointment confirmed</li></ul></div>"
      },
      {
        "id": "ahc_reports",
        "title": "Reports",
        "type": "static",
        "htmlString": true,
        "desc": "<div> <div style='margin-bottom: 4px'><b> When will I receive my Health Checkup reports? </b> </div> <div> - Your Health Checkup report shall be available for download within 72 hours. Steps to download report: </div><ul><li> Select the <b> Profile </b> icon on the top-right corner</li><li> Select <b> Track orders </b></li><li>Select Download Report </li> </ul></div>"
      },
      {
        "id": "ahc_refund",
        "title": "Refund",
        "type": "static",
        "htmlString": true,
        "desc": "<div> <div style='margin-bottom: 4px'><b> In case of advance payment, will I be refunded for not making an appearance on the appointment date? </b> </div> <div> - Yes </div><ul><li> Sponsored Health Checkup Packages will be available for booking again instantly. </li><li> Payments made through a payment gateway or a wallet will be refunded within 24 hours. </li> </ul></div>"
      },
      {
        "id": "ahc_documents",
        "title": "Documents",
        "type": "static",
        "htmlString": true,
        "desc": "<div> <div style='margin-bottom: 4px'><b> Are any documents required to undergo Health Checkup via MediBuddy? </b> </div> <div> - You are required to carry the following documents: </div><ul><li> Appointment confirmation letter (Available in your Email or the <b> Track Your Orders </b> section) </li><li> Government issued Photo ID Card (Aadhar Card / Passport etc)</li> </ul></div>"
      },
      {
        "id": "ahc_rescheduling",
        "title": "Re-Scheduling",
        "type": "static",
        "htmlString": true,
        "desc": "<div> <div style='margin-bottom: 4px'><b> Can an appointment be rescheduled? </b> </div> <div> - Yes</div><ul><li> Select the <b> Profile </b> icon on the top-right corner</li><li> Select <b> Track orders </b></li><li>Select Re-schedule </li></ul></div>"
      },
      {
        "id": "ahc_How_many_days_in_advance_do",
        "title": "How many days in advance do I need to book an appointment?",
        "type": "static",
        "htmlString": true,
        "desc": "<div><div> This depends on the avaiilability of the diagnostic centres. During periods of high demand, the slots may not be available for at least 2 days from the date of booking.</div> </div>"
      },
    //  {
    //    "id": "ahc_scheduling",
    //    "title": "Scheduling",
    //    "type": "static",
    //    "htmlString": true,
    //    "desc": "<div> <div style='margin-bottom: 4px'><b> How many days in advance do I need to book an appointment? </b> </div> <div> - This depends on the availability of the diagnostic centres. During periods of high demand, the slots may not be available for at least 2 days from the date of booking.</div></div>"
    //  },
    {
      "id": "ahc_befor_hc",
      "title": "What should I do before a Health Checkup?",
      "type": "static",
      "htmlString": true,
      "desc": "<div> <div style='margin-bottom: 4px'> You must follow the mentioned practises before a Health Checkup: </div> <ul> <li> Unless otherwise specified, you must fast overnight for 12 hours before the appointment. Only water can be consumed. </li> <li> Avoid smoking or consuming alcohol for a minimum of 24 hours before the Health Checkup. </li> <li> Women should not undergo Health Checkups at the time of menstruation. </li> <li> Pregnant women should not undertake X-Ray. </li> <li> You may carry urine & stool sample in a sterilized container obtainable from the nearest pharmacy. (if applicable) </li> <li> Specific to Centre visit Health Checkup: <ul> <li> Registration is mandatory at the medical centre; this may take 15-20 minutes. </li><li> Health Checkups at your medical centre may take 4-5 hours on weekdays and 5-6 hours on Saturdays. Kindly plan accordingly. </li> </ul> </li>  </ul></div>"
    },
      {
        "id": "ahc_cancellation",
        "title": "Cancellation",
        "type": "static",
        "htmlString": true,
        "desc": "<div> <div style='margin-bottom: 4px'><b> Can an appointment be cancelled? </b> </div> <div> - Yes</div><ul><li> Select the <b> Profile </b> icon on the top-right corner</li><li> Select <b> Track orders </b></li><li>Select Cancel </li></ul></div>"
      },
      {
        "id": "ahc_support",
        "title": "Support",
        "type": "static",
        "htmlString": true,
        "support": true,
        "mobile": "9999991555",
        "email": "customerhealthcheck@medibuddy.in",
        "desc": "<div><div> You may reach us through the following </div> </div>"
      },
    ]
  },
  {
    "id": "Surgery",
    "title": "Surgery",
    "type": "dynamic",
    "options": [
      {
        "id": "surgery_about",
        "title": "About MediBuddy Surgery Care?",
        "type": "dynamic",
        "options": [
          {
            "id": "surgery_benefits",
            "title": "What are the benefits of Medibuddy Surgery Care ?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": false,
            "desc": "<div>If you choose Medibuddy as your health partner, we are pleased to offer the following benefits:<br><li>Dedicated Carebuddy Support through the hospitalization journey</li><li>Choice of doctor and hospital from a wide range of network</li><li>One complimentary In person consult with an expert Doctor</li><li>Coordination of Pre-authorization and admission paperwork</li><li>Dedicated clinical team for monitoring your condition with regular check-ins</li><li>Complimentary pick up and drop facility on the day of admission/discharge days</li><li>0 % EMI for cash or reimbursement cases</li><li>Facilitating of medicines and test orders</li><li>Arrangement of any other necessary expert consultations or sessions</li></div>"
          },
          {
            "id": "surgery_process",
            "title": " How will the overall process work?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div><img style='width: 100%' src='https://views.medibuddy.in/surgeryBanners/surgery_patient_journey.png' alt='Image'></div>You can reach out to us for assistance by filling <button class='typeform-button' style={typeformButtonStyle}>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page' style='color: blue;' >here.</a></div></div>"
          },
          {
            "id": "surgery_avail_services",
            "title": "How can I reach out to avail this service?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div> You can reach out to us via MediBuddy web, app or IPD assist helpline.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here.</a></div></div>"
          },
          {
            "id": "surgery_carebuddy",
            "title": "Who is a Carebuddy and how will I get help from Carebuddy ?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>A CareBuddy is a dedicated support professional assigned to you by Medibuddy to guide you through your entire hospitalization journey, from initial consultation to discharge and recovery. Your CareBuddy will assist with choosing the right hospital and doctor, decision-making for hospitalization (if prescribed), completing insurance paperwork, explaining policy coverage and costs, booking beds, complimentary pickup and drop services for hospitalization, facilitating a smooth discharge process, and handling post-discharge assistance.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here.</a></div></div>"
          },
          {
            "id": "surgery_complementary_consultation",
            "title": "What is a complimentary consultation and how many complimentary consultations can I get?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>We are pleased to offer one first complimentary consultation when you book your appointment through Medibuddy. This special offer is our way of expressing appreciation for choosing the convenient and efficient Medibuddy platform.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here.</a></div></div>"
          },
          {
            "id": "surgery_consultation",
            "title": "How will the consultation with the Specialist be conducted - is it online or offline?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Our first complimentary consultation is conducted offline to ensure a thorough physical examination by the specialist, which we consider crucial for accurate assessment and recommendations. In exceptional cases, where appropriate and upon clearance from the hospital team, we may facilitate an online consultation based on specific needs and case details.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          }
        ],
      },
      {
        "id": "surgery_hospitalization_related",
        "title": "Hospitalization related",
        "type": "dynamic",
        "options": [
          {
            "id": "surgery_associated_hospitals",
            "title": "What are the hospitals associated with you?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>We have a presence in 150+ cities including all metro and non metro cities (T-2 & T-3) cities as well and we have maintained a balance of Primary, Secondary care hospitals and for complicated cases we have Tertiary care hospitals as well all these hospitals are cashless hospitals and empaneled with the TPA.<br>Some notable hospitals are : Himagiri, Vasan, MIOT etc.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_choose_hospitals",
            "title": "How can Medibuddy help me in choosing the right hospital?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div> Medibuddy helps you choose the right hospital by offering detailed information on various hospitals based on location, specialties, available doctors, and facilities. Additionally, Medibuddy ensures the hospital accepts your insurance, guiding you to the best option for your needs.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_hospitals_info",
            "title": "What information will I find about hospitals/ doctors on MediBuddy?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div> On MediBuddy, we will provide  you with comprehensive information about hospitals and doctors, including their specialties, experience, and details on the doctor’s OPD dates and times.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_post_hospital_care",
            "title": "Can MediBuddy help me with post-hospital care",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Yes, MediBuddy can assist with post-hospital care by providing a dedicated Clinical Care team to follow up with post-discharge consultations, facilitate medication management, and connect you with the hospital or doctor for any further assistance, including providing information on treatment options and ensuring effective communication with your care team.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_documents",
            "title": "What are the important documents I need to carry during the consultation and hospitalization?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>During consultation and hospitalization, you should carry identification proof (Aadhar card), insurance documents (E-Card and policy documents), prescriptions, investigations reports, Medibuddy OPD/IPD appointment confirmation.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_hospital_admission_test",
            "title": " Will MediBuddy facilitate tests needed before or after the Hospital Admission?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Yes, MediBuddy will support in booking your tests as suggested by the doctor. In cases where a home-sample collection is possible, we support the Patient with the same on MediBuddy exclusive discounts, This also helps reduce Pre-Post Hospitalization expenses as well. All this will be done using the MediBuddy app.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
        ]
      },
      {
        "id": "surgery_coverage",
        "title": "Coverage",
        "type": "dynamic",
        "options": [
          {
            "id": "surgery_insurance",
            "title": "Can the hospitalization be covered under my health insurance?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Typically, health insurance covers both day care and hospital admissions for medical and surgical management. However, coverage is subject to medical adjudication based on your specific condition. Your CareBuddy will help you understand your insurance coverage for your particular situation before admission.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_insurance_tpa",
            "title": "Which Insurances/ TPA will be covered if we go for the surgery through Medibuddy?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>We work with a range of insurance providers, and coverage will depend on the hospital you choose. Your CareBuddy will help you select a hospital covered by your insurance and ensure that cashless facilities are available. <br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_insurance_covered",
            "title": "How can I determine if the hospital suggested by Medibuddy is covered under my insurance?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>We can help you identify if the hospital suggested by Medibuddy is covered under your insurance through our extensive network. Your CareBuddy will assist you in selecting a hospital that aligns with your insurance coverage and provide all necessary insurance-related details.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_insurance_reimbursement",
            "title": "How can Medibuddy help me file reimbursement for pre and post hospitalization expenses?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Medibuddy can assist with filing reimbursement for pre- and post-hospitalization expenses by guiding you through the claim submission process and providing details on the required documentation for a smooth reimbursement.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_insurance_cashless",
            "title": "How do I get help from Medibuddy if I face any issue during the hospitalization related to the cashless facility?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>If you encounter any issues with the cashless facility during hospitalization, you can reach out to your CareBuddy for assistance. Our Medibuddy team will work with the hospital’s billing department to resolve any discrepancies and ensure a smooth discharge process.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          }
        ]
      } ,
      {
        "id": "surgery_costs",
        "title": "Surgery Costs",
        "type": "dynamic",
        "options": [
          {
            "id": "surgery_additional_charges",
            "title": "Are there any additional charges that I should be aware of during the consultation?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>We’re pleased to offer the first consultation complimentary through Medibuddy. However, additional charges may apply for specialized tests, procedures, or other specialist consultations as advised by your doctor. Medibuddy will assist you in claiming these expenses through pre- and post-hospitalization processes if surgery is performed.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_additional_rates_hospitals",
            "title": "Are there any additional charges that I should be aware of during the hospitalization if I go through a cashless procedure?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Even with a cashless procedure, you may encounter additional charges for services not covered by your insurance, such as specific medications, non-medical expenses, premium room upgrades, or advanced procedures. Medibuddy will provide you with details on what is covered by your insurance and what might incur extra costs before hospitalization, and will recommend the best hospital and surgeon within your coverage.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_additional_charges_family",
            "title": "Do I have to pay any additional charges if I avail your services for my family members ?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Thank you for choosing Medibuddy. There are no additional charges for referring your family members to our services; we are happy to assist them with IPD assistance. Additionally, we offer a complimentary first consultation for any of your family members through the Medibuddy platform.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_additional_package_rates",
            "title": "Do you have separate package rates? What are the agreed package rates with you?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Medibuddy does not offer separate package rates. Instead, we ensure that the agreed-upon rates between your insurer and the hospital are followed, and we help reduce any unexpected costs.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          }
        ]
      },
      {
        "id": "surgery_support",
        "title": "Support",
        "type": "dynamic",
        "options": [
          {
            "id": "surgery_feedback",
            "title": "In case of any feedback/ review related to any services, where can I write or call?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": false,
            "desc": "<div><div>For feedback or reviews related to services booked through Medibuddy, you can email us at Support_surgerycare@medibuddy.in, use the support option in the Medibuddy app, or call us at 9999991555 for assistance.</div></div>"
          },
          {
            "id": "surgery_clinical_health_queries",
            "title": "Can Medibuddy help with any clinical/ health related queries during the entire process?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Yes, Medibuddy can assist with clinical-related queries throughout the process. After your initial consultation, our dedicated clinical care team will connect with you and provide ongoing support and address any clinical concerns until your recovery, ensuring you receive the best possible care.<br>You can reach out to us for assistance by filling <button id='typeformButton1' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_hospital_helping",
            "title": "What if I have chosen a doctor and hospital already, will you still be helping me with the surgery care assistance ?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>If you have already chosen a doctor and hospital, we can still assist by providing additional second opinions from doctors at Medibuddy-empaneled hospitals if you're interested. Should you decide to proceed with us, we will offer complete end-to-end support. However, if you choose not to seek further opinions from Medibuddy, you will need to continue with your selected doctor and hospital without additional support from Medibuddy, as our assistance is limited to our empaneled network.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_hospital_helping",
            "title": "Can you help me if I have already seen a doctor and have a prescription for surgery ?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Yes, Medibuddy can assist you even if you have a prescription for surgery. We can provide additional second opinions from doctors at Medibuddy-empaneled hospitals, help you select a hospital within our network, coordinate pre-authorization and admission paperwork, manage the surgery process, and support both pre- and post-operative care. If you choose not to seek further opinions from Medibuddy, you will need to proceed with your selected doctor and hospital without additional support from us, as our assistance is limited to our empaneled network.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_hospital_admission_assistance",
            "title": "Can I avail your assistance for any kind of hospital admission for reasons other than surgery ?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>Yes, you can avail our assistance for any type of hospital admission, including those for medical conditions which require treatment after admission or hospitalization,  minor day care procedures, or treatments other than surgery.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_not_happy_with_consultation",
            "title": "What if I am not happy with MB offered consultation & Choice of Hospital given?",
            "type": "static",
            "htmlString": true,
            "containsTypeForm": true,
            "desc": "<div><div>MediBuddy will understand the reason for the same and facilitate a change in hospital incase the reason is justified. Patient is always free to choose the hospital of his/her  choice in case the provided hospital is unsatisfactory. We offer one additional second opinion free of charge. Any subsequent second opinions requested will incur charges.<br>You can reach out to us for assistance by filling <button class='typeform-button' style='color: blue;'>this form</button> and you will receive a call from your Care Buddy. You can also call us on 080-47186330. <br>You can learn more about MediBuddy surgery assistance <a href = 'https://www.medibuddy.in/surgery-care?utm_source=faq-page'  style='color: blue;' >here</a>.</div></div>"
          },
          {
            "id": "surgery_support_section",
            "title": "Support",
            "type": "static",
            "desc": "Please, write your query to us",
            "support": true,
            "mobile": "9999991555",
            "email": "hello@medibuddy.in"
          },
        ]
      }
    ]
  },
  {
    "id": "Login",
    "title": "Login",
    "type": "dynamic",
    "options": [
      {
        "id": "Login_password",
        "title": "Forgot my password",
        "type": "static",
        "htmlString": true,
        "desc": "<ul> <li> Try clicking on forgot password and try resetting the same. </li> <li> If this still unresolved, write to us below </li></ul>",
        "support": true,
        "mobile": "9999991555",
        "placeholder": "Concern - ",
        "email": "hello@medibuddy.in"
      },
      {
        "id": "Login_username",
        "title": "Forgot my username",
        "type": "static",
        "htmlString": true,
        "desc": "<ul> <li> If you are here from as part of Corporate or Insurer Clients, try using your Email ID. </li> <li> If this still doesnt work, please contact your HR related to the same </li></ul>"
      },
      {
        "id": "Login_lock",
        "title": "My Account is locked",
        "type": "static",
        "desc": "Accounts are locked if we detect multiple failed attempts. We unblock the account in 30 mins automatically."
      },
      {
        "id": "login_support",
        "title": "Support",
        "type": "static",
        "desc": "Please, write your query to us",
        "support": true,
        "mobile": "9999991555",
        "email": "hello@medibuddy.in"
      },
    ]
  },
  {
    "id": "Gold",
    "title": "Gold",
    "type": "dynamic",
    "options": [
      {
        "id": "Gold_1",
        "title": "What is MediBuddy Gold? And why should I Buy it?",
        "type": "static",
        "desc": "MediBuddy Gold is a medical care membership for your family. It offers unlimited* free doctor consultations online, over audio/video calls for 6 members of your family"
      },
      {
        "id": "Gold_2",
        "title": "How many consultations will I get for free?",
        "type": "static",
        "desc": "You will get Unlimited* FREE Consultations with MediBuddy Gold However, The use of free consultations via our Gold plan shall be restricted on a daily, monthly, weekly and yearly basis. Capping of which is subjected to change. See our Gold Fair Usage Policy to know more."
      },
      {
        "id": "Gold_3",
        "title": "With MediBuddy Gold, can I get free  consultations for my mother and father?",
        "type": "static",
        "desc": "MediBuddy Gold is free for 6 family members. Therefore, anyone in your family ( upto 6 members ) can get free consultations."
      },
      {
        "id": "Gold_5",
        "title": "Can I get free consultations in every  department?",
        "type": "static",
        "desc": "MediBuddy Gold is applicable for all 18+ departments/specialities."
      },
      {
        "id": "Gold_6",
        "title": "Will I be able to cancel my membership  later on?",
        "type": "static",
        "desc": "Yes, you can cancel your membership anytime"
      },
      {
        "id": "Gold_7",
        "title": "Will I get refund, if I am not satisfied with the membership plan?",
        "type": "static",
        "desc": "We strive to provide the best healthcare services to you. For any service issues, please reach out to us."
      },
      {
        "id": "Gold_8",
        "title": "View Gold Fair Usage Policy",
        "type": "static",
        "desc": "<a href='https://www.medibuddy.in/terms/fair-usage-tc' style='color: blue'>Click Here</a> to view gold fair usage policy"
      },
      {
        "id": "Gold_9",
        "title": "MediBuddy Gold subscription is not active after payment ?",
        "type": "static",
        "htmlString": true,
        "placeholder": "Phone Number -              Concern- ",
        "desc": "<div style='margin-bottom: 4px'>If you face any issue with your Gold Subscription after making a payment, Please write to us below</div>",
        "support": true,
        "email": "hello@medibuddy.in"
      },
      {
        "id": "Gold_Support",
        "title": "Support",
        "type": "static",
        "htmlString": true,
        "support": true,
        "mobile": "9999991555",
        "email": "hello@medibuddy.in",
        "desc": "<div><div> You may reach us through the following </div> </div>"
      }
    ]
  },
  {
    "id": "covid",
    "title": "Covid-19 RT-PCR",
    "type": "dynamic",
    "options": [
      {
        "id": "covid_Book_Appointment",
        "title": "Book Appointment",
        "type": "dynamic",
        "options": [
          {
            "id": "covid_Book_Appointment_1",
            "title": "How do I book an appointment for a COVID- 19 RT-PCR Swab Test?",
            "type": "static",
            "htmlString": true,
            "desc": "<div> <div style='margin-bottom: 4px'> We suggest that you book the appointment at least 2 days prior to the intended date.Please find the steps to book an appointment: </div><ul><li> Please log on to <a href='https://www.medibuddy.in/'>  https://www.medibuddy.in/ </a>  or download the Medibuddy app</li><li> Select Covid-19 icon </li> <li> Choose Covid-19 RT-PCR Swab Test package </li>  <li>Please click on the diagnostic centre of your choice<ul><li>You can select amongst options available for centre and home visits</li><li> Choose from available slots</li> <li> Update your residential address (Incase home visit has been selected) </li>  </ul></li><li> Confirm your contact information </li><li> Follow further steps for the payment </li><li> Appointment request raised </li></ul></div>"
          },
          {
            "id": "covid_Book_Appointment_2",
            "title": "How do I check whether my appointment has been confirmed?",
            "type": "static",
            "htmlString": true,
            "desc": "<div style='margin-bottom: 4px'><div> Steps to check appointment confirmation-</div> <ul> <li> Select the Profile icon on the top-right corner </li><li> Select Track Order </li> <li> Check the status of the order-confirmed </li></ul> </div>"
          },
          {
            "id": "covid_Book_Appointment_3",
            "title": "I have booked a slot, but have not received confirmation. How long will it take to get my appointment confirmed?",
            "type": "static",
            "htmlString": true,
            "desc": "<div style='margin-bottom: 4px'>The appointment will be confirmed one day prior to your appointment date </div>"
          },
          {
            "id": "covid_Book_Appointment_4",
            "title": "If I have booked an appointment, does this mean my sample pickup is confirmed?",
            "type": "static",
            "htmlString": true,
            "desc": "<div style='margin-bottom: 4px'><div> No </div><div> Once the sample pickup is confirmed, you will receive a confirmation message on your registered email id and phone number respectively </div> </div>"
          }
        ]
      },
      {
        "id": "covid_Scheduling",
        "title": "Scheduling",
        "type": "dynamic",
        "options": [
          {
            "id": "covid_Scheduling_1",
            "title": "How many days in advance do I need to book an appointment?",
            "type": "static",
            "htmlString": true,
            "desc": "<div style='margin-bottom: 4px'>This depends on the availability of the technicians/slots at the medical centers. During periods of high demand, you might face an issue. Hence we suggest you book according to the availability of slots.</div>"
          },
          {
            "id": "covid_Scheduling_2",
            "title": "I have scheduled an appointment for today. No one has contacted me yet?",
            "type": "static",
            "htmlString": true,
            "desc": "<div style='margin-bottom: 4px'>The sample will be collected between 9 am to 6 pm. Request you to wait for the call of the technician.</div>"
          }
        ]
      },
      {
        "id": "covid_Sample_Pickup",
        "title": "Sample Pickup",
        "type": "dynamic",
        "options": [
          {
            "id": "covid_Sample_Pickup_1",
            "title": "Who will come for my sample pickup? How do I connect with my technician?",
            "type": "static",
            "htmlString": true,
            "desc": "<div style='margin-bottom: 4px'>The technician will visit for the sample collection. You will receive a call well in    advance before his arrival on the appointment date.</div>"
          },
          {
            "id": "covid_Sample_Pickup_2",
            "title": "Is my technician COVID-free? How do I ensure that my sample pickup is safe and    hygienic?",
            "type": "static",
            "htmlString": true,
            "desc": "<div style='margin-bottom: 4px'> <ul> <li> Technicians always wear clean, non-sterile gloves </li> <li> They put on fresh gloves before every new sample collection.</li> <li> Thorough hand hygiene is observed before wearing gloves and after taking them off. </li> <li> Technicians use one-time collection kits to ensure that new samples are not contaminated by the remnants of the earlier samples. </li> <li> Technician ensures that the customer is comfortable and in a clean and hygienic place before commencing with sample collection. </li> </ul> </div>"
          },
          {
            "id": "covid_Sample_Pickup_3",
            "title": "The technician did not come for sample collection, what should I do?",
            "type": "static",
            "htmlString": true,
            "desc": "<div style='margin-bottom: 4px'> <div> This is an anomaly. Our technicians always call before visiting to collect the sample to confirm your availability. If the technician has not come for sample collection after your order is confirmed, it is usually because (s)he was not able to reach you over the phone to confirm your availability. In this case we request you to kindly: </div> <ul> <li> Reschedule your appointment, or </li> <li> Cancel your appointment</li> </ul> </div>"
          }
        ]
      },
      {
        "id": "covid_Re-Scheduling",
        "title": "Re-Scheduling",
        "type": "static",
        "htmlString": true,
        "desc": "<div style='margin-bottom: 4px'> <div> Can an appointment be rescheduled? </div> <div> Yes </div> <div> Steps to reschedule your appointment- </div> <ul> <li> Please log on to <a href='https://www.medibuddy.in/'> https://www.medibuddy.in/  </a> or download the Medibuddy app </li> <li> Select the Profile icon on the top-right corner </li> <li> Select the Profile icon on the top-right corner </li><li> Select Track orders </li><li> Select Re-schedule </li> </ul> </div>"
      },
      {
        "id": "covid_Cancellation",
        "title": "Cancellation",
        "type": "static",
        "htmlString": true,
        "desc": "<div style='margin-bottom: 4px'> <div> Can an appointment be cancelled? </div> <div> Yes </div> <div> Steps to cancel your appointment- </div> <ul> <li> Please log on to <a href='https://www.medibuddy.in/'> https://www.medibuddy.in/  </a> or download the Medibuddy app </li> <li> Select the Profile icon on the top-right corner </li> <li> Select the Profile icon on the top-right corner </li><li> Select Track orders </li><li> Select Cancel </li> </ul> </div>"
      },
      {
        "id": "covid_Reports",
        "title": "Reports",
        "type": "dynamic",
        "options": [
          {
            "id": "covid_Reports_1",
            "title": "When will I receive my Covid-19 RT-PCR tests reports?",
            "type": "static",
            "htmlString": true,
            "desc": "<div style='margin-bottom: 4px'> <div> Your Covid-19 RT-PCR tests report shall be available for download within 24-72 hours of sample collection Please note that due to the unprecedented spike in the number of COVID-19 cases, labs across India are facing shortage of manpower. There might be delays in processing of the sample and uploading of reports - we will keep you duly informed of any changes with respect to the same. </div> <div> Steps to download the report- </div> <ul> <li> Select the Profile icon on the top-right corner</li> <li> Select Track orders</li> <li> Select  Download Report</li> </ul> </div>"
          },
          {
            "id": "covid_Reports_2",
            "title": "What should I do in case my report is delayed?",
            "type": "static",
            "htmlString": true,
            "support": true,
            "email": "covidescalations@medibuddy.in",
            "desc": "<div style='margin-bottom: 4px'> Request you to drop a mail at </div>"
          },
          {
            "id": "covid_Reports_3",
            "title": "How do I interpret my report?",
            "type": "static",
            "htmlString": true,
            "desc": "<div style='margin-bottom: 4px'> Report interpretation will be done by Doctors. Request you to book an online consultation with our renowned doctors through our website or app to get better clarity. </div>"
          }
        ]
      },
      {
        "id": "covid_Documents",
        "title": "Documents",
        "type": "static",
        "htmlString": true,
        "desc": "<div style='margin-bottom: 4px'> <div>  Are any documents required to undergo Covid-19 RT-PCR testing via MediBuddy? </div> <div> You are required to carry/keep the following documents: </div> <ul> <li> Appointment confirmation letter (Available in your Email or the Track Your Orders section) </li> <li> Government-issued Photo ID Card (Aadhar Card / Passport etc) </li> </ul> </div>"
      },
      {
        "id": "covid_Refund",
        "title": "Refund",
        "type": "static",
        "htmlString": true,
        "desc": "<div style='margin-bottom: 4px'> <div>  In case of advance payment, will I be refunded for not making an appearance/not available for Home visit on the appointment date? </div> <div>Yes </div> <div> You are required to carry/keep the following documents: </div> <div> Please note that in order to meet the guidelines on lockdown rolled out by various state governments, we are operating at reduced capacity. Some of our staff members are also unavailable on account of COVID-19 infection. Some refund requests might be delayed by additional 4-5 working days. Please be assured that your money will be safe - we are trying to process refunds as soon as we can! </div></div>"
      },
      {
        "id": "covid_Support",
        "title": "Support",
        "type": "static",
        "htmlString": true,
        "support": true,
        "mobile": "9999991555",
        "email": "covidescalations@medibuddy.in",
        "desc": "<div style='margin-bottom: 4px'> <div>  Where can I receive more information ? </div> <div>You may reach us through the following </div></div>"
      }
    ]
  },
  {
    "id": "vaccination",
    "title": "COVID Vaccination",
    "type": "dynamic",
    "options": [
      {
        "id": "vaccination_1",
        "title": "What is a Vaccination Location?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> <div style='margin-bottom: 6px'> MediBuddy provides two ways of booking your vaccination. </div> <div style='margin-bottom: 6px'><b>Lab Centre/At Hospital </b> - You can book on the platform and then visit the hospital you chose on the date of Vaccination. </div><div style='margin-bottom: 6px'><b>Office Campus/On-site </b> - You can book on the platform for a camp specifically arranged to vaccinate the employees of your company. You need to go to the camp location (most likely your office campus), and get your vaccination done. </div> </div>"
      },
      {
        "id": "vaccination_2",
        "title": "I clicked on a wrong city by mistake, how can I change it?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> You can click on the city name on the top right corner of the screen and change your location </div>"
      },
      {
        "id": "vaccination_3",
        "title": "I selected the wrong location for Vaccination. How can I change it?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> You can click on box above your appointment which mentions either “Hospital Visit” or “On-site booking” and change your Vaccination location. </div>"
      },
      {
        "id": "vaccination_4",
        "title": "I can’t select a slot in while trying to book at “Lab Centre/At Hospital”",
        "type": "static",
        "htmlString": true,
        "desc": "<div> If you’re booking for Vaccination at a hospital, we will send you a time slot on the day you requested when we confirm your appointment. Due to the uncertainty surrounding the Vaccine supply, we are not able to provide time slots for selections, yet. </div>"
      },
      {
        "id": "vaccination_5",
        "title": "I booked my appointment but now want to change or cancel my appointment. How can I do it?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> You can view your appointment in the “Order Details” link that is visible after clicking on the profile icon on the top right corner of your screen. You can modify or cancel your appointment there. But if you’re cancelling your appointment, please get in touch with us so that we can arrange an alternate appointment for you </div>"
      },
      {
        "id": "vaccination_6",
        "title": "I did not take my Dose 1 Vaccination through MediBuddy. Can I book my Dose 2 on MediBuddy?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> Yes, you definitely can </div>"
      },
      {
        "id": "vaccination_7",
        "title": "Why do I have to provide my Dose 1 details while booking my Dose 2 appointment?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> We need your details so that we can confirm your appointment with respect to the brand of your Dose 1 Vaccination. </div>"
      },
      {
        "id": "vaccination_8",
        "title": "What do I have to carry on the day of my appointment?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> You would need to carry your ID proof (Aadhaar, PAN, Voter ID, etc.) along with a copy of the confirmation email we sent you. Please make sure you’re carrying the confirmation email of each and every person that is attending the appointment. </div>"
      },
      {
        "id": "vaccination_9",
        "title": "How do I get an invoice for the payment I made on the platform?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> If you made a payment, you can find your invoice in the “Order Details” page, in your appointment details </div>"
      },
      {
        "id": "vaccination_10",
        "title": "Can I download my Vaccination Certificate through MediBuddy?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> We’re going to provide this service shortly </div>"
      },
      {
        "id": "vaccination_11",
        "title": "What to do if I’m not feeling well after taking Vaccination?",
        "type": "static",
        "htmlString": true,
        "desc": "<div> Symptoms like Fever, fatigue, etc. are common after taking the Vaccination as a part of your Immune Response. However, if the symptoms don’t go away after 2-3 days please consult a doctor. You can talk to a doctor from the comfort of your home via MediBuddy App as well. </div>"
      },
      {
        "id": "vaccination_support",
        "title": "Support",
        "type": "static",
        "htmlString": true,
        "support": true,
        "email": "vaccinehelpdesk@medibuddy.in",
        "mobile": "9999991555",
        "desc": "<div><div> You may reach us through the following </div> </div>"
      }
    ]
  },

]

const DAfaqsData = [
  {
    "id": "Consults",
    "title": "Consultation",
    "type": "dynamic",
    "options": [
      {
        "id": "Consults_cost",
        "title": "How much does it cost?",
        "type": "static",
        "desc": "Our consultation fees are much more reasonable than clinic rates. You can save up to 60% by consulting India's top doctors through DocsApp. Adding the savings of NOT travelling in traffic and waiting for appointments, DocsAppD is a deal you cannot resist!"
      },
      {
        "id": "Consults_specialists",
        "title": "Do you have specialists (Gynecologists, Psychologists, etc.)?",
        "type": "static",
        "htmlString": true,
        "desc": "<div style='margin-bottom: 4px'> Yes, we strive hard to ensure that you get to discuss your concern with a MD or MS who specializes in that area. For eg: If you have childcare concerns we connect you with a Pediatrician , for Women’s issues we have highly experienced Gynecologists etc. These are some of the specialties we have: <ul> <li>General Physician </li> <li>Sexology </li> <li> Stress and Mental Health </li> <li>Dermatology </li> <li> Gynaecology</li> <li> Weight Management </li> <li> Pediatrics </li> <li> Gastroenterology</li> <li> Neurology </li> <li> Orthopedics </li> <li> Diabetes Consult</li> <li> Cardiology </li> <li> Oncology </li></ul> </div>"
      },
      {
        "id": "Consults_speak_again",
        "title": "Can I speak with the same doctor again?",
        "type": "static",
        "desc": "Yes. All consultations are eligible for one free followup with the same doctor within three days of the consultation.After 3 days you may be asked to pay for the followup. Please know that doctor will be available only in their slot timings to answer your follow ups"
      },
      {
        "id": "Consults_emergencies",
        "title": "Can I take online consultation for emergencies?",
        "type": "static",
        "desc": "No. This app is for primary care- the kind of problems that your family doctor can help you with. Doctors on this app can only help you with consultation based issues that can be shared in the form of messages, pictures and medical reports. In case of a life- threatening emergency, please call an ambulance or head to a nearby hospital."
      },
      {
        "id": "Consults_prescribe",
        "title": "What medicines do you prescribe?",
        "type": "static",
        "desc": "We mostly have allopathic doctors on our team. We do not advise on homeopathy, Ayurveda or any other form of treatment. We suggest over- the- counter (OTC) medicines and home remedies, just as your family doctor would. The same can be ordered on our app and will be delivered at your doorstep by our medicine delivery partners."
      },
      {
        "id": "Consults_doctor_call",
        "title": "When will the doctor call me?",
        "type": "static",
        "desc": "Doctor usually calls you in 30 minutes. Right now, Due to high demand with an increase in covid cases, doctors can take upto 3 hrs to call you."
      },
      {
        "id": "Consults_doctor_didnot_call",
        "title": "Doctor hasn't called me even after 3 hours?",
        "type": "static",
        "htmlString": true,
        "support": true,
        "placeholder": "Concern - ",
        "email": "hello@docsapp.in",
        "desc": "<div> If the doctor has not called even after 3 hours. Please select</div>",
        "fetchConsults": true
      },
      {
        "id": "Consults_prescription",
        "title": "I haven't received prescription for my consultation",
        "type": "static",
        "htmlString": true,
        "placeholder": "Concern - ",
        "email": "hello@docsapp.in",
        "desc": "<div> In case of any issue with Prescription delivery, please check your <b>Medical Records</b> section in Menu on Home page. Please call us at <a href='tel:08047191919'> 08047191919 </a> if the issue persists</div>"
      },
      {
        "id": "Consults_change_doctor",
        "title": "I want to change my doctor?",
        "type": "dynamic",
        "options": [
          {
            "id": "Consults_change_doctor_1",
            "title": "Issue with the current doctor",
            "type": "static",
            "support": true,
            "info": "issueCurrentDoctor",
            "htmlString": true,
            "fetchConsults": true,
            "dropDown": true,
            "fetchDoctors": true,
            "placeholder": "Concern - ",
            "email": "hello@docsapp.in",
            "desc": "<div> We are sorry you had an issue. Please select the type of issue</div>"
          },
          {
            "id": "Consults_change_doctor_2",
            "title": "I want another doctor",
            "type": "static",
            "support": true,
            "info": "wantAnotherDoctor",
            "htmlString": true,
            "fetchConsults": true,
            "fetchDoctors": true,
            "placeholder": "Concern - ",
            "email": "hello@docsapp.in",
            "desc": "<div> We have given you the best experienced doctor available at this moment. Incase you still want to contact a different doctor please select</div>"
          }
        ]
      },
      {
        "id": "Consults_support",
        "title": "Support",
        "type": "static",
        "htmlString": true,
        "support": true,
        "placeholder": "Concern - ",
        "mobile": "08047191919",
        "email": "hello@docsapp.in",
        "desc": "<div> Please reach us below </div>"
      }
    ]
  },
  {
    "id": "Gold",
    "title": "Gold",
    "type": "dynamic",
    "options": [
      {
        "id": "Gold_1",
        "title": "What is DocsApp Gold? Why should I buy it?",
        "type": "static",
        "desc": "DocsApp Gold is a medical care membership for your full family. It offers unlimited free doctor consultations on DocsApp for 12 whole months for your full family of 6 members."
      },
      {
        "id": "Gold_2",
        "title": "What are the benefits of DocsApp Gold?",
        "type": "static",
        "desc": "Unlimited Free Consultations Free For Full Family of 6. One-time yearly fee of Rs.2499. Free consultations for all your health problems. Free delivery of medicines & up to 15% OFF. Home sample pickup for lab tests & up to 60% OFF."
      },
      {
        "id": "Gold_3",
        "title": "Can I get a free consultation in every department?",
        "type": "static",
        "desc": "Yes, DocsApp Gold is applicable for all the 20 departments."
      },
      {
        "id": "Gold_4",
        "title": "How many consultations will I get for free?",
        "type": "static",
        "htmlString": true,
        "desc": "<div ><div style='margin-bottom: 4px'> You will get Unlimited FREE Consultations with DocsApp Gold</div><div> However, The use of free consultations via our Gold plan shall be restricted to 5 consultations a week. Capping is subjected to change. </div> </div>"
      },
      {
        "id": "Gold_5",
        "title": "Will I get a refund if I am not satisfied with the membership plan?",
        "type": "static",
        "desc": "Yes, DocsApp guarantees 100% refund if you are not satisfied."
      },
      {
        "id": "Gold_6",
        "title": "Are there any more benefits to DocsApp Gold?",
        "type": "static",
        "desc": "We are constantly working to add more benefits to DocsApp Gold. Watch out for more updates"
      },
      {
        "id": "Gold_7",
        "title": "With DocsApp Gold, can I get free consultations for my mother and father?",
        "type": "static",
        "desc": "DocsApp Gold is free for your full family of 6. Therefore, everyone in your family (mother, father, son, daughter, grandmother, grandfather, etc.) can get free consultations."
      },
      {
        "id": "Gold_8",
        "title": "Will I be able to cancel my membership later on?",
        "type": "static",
        "desc": "Yes, you can cancel your membership anytime in case you are not satisfied."
      },
      {
        "id": "Gold_9",
        "title": "DocsApp Gold subscription is not active after payment ?",
        "type": "static",
        "htmlString": true,
        "placeholder": "Phone Number -              Concern- ",
        "desc": "<div style='margin-bottom: 4px'>If you face any issue with your Gold Subscription after making a payment, Please write to us below</div>",
        "support": true,
        "email": "hello@docsapp.in"
      }
    ]
  }
]

module.exports = {
  faqs,
  faqsData,
  DAfaqsData
}