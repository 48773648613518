import ReactGa from 'react-ga';
import {POST} from './requestHelper'
import config from '../config';

const event = {
  send: (data) => {
    ReactGa.event(data);
  },
  pageView: () => {
    ReactGa.pageview(window.location.pathname + window.location.search);
  },
  pushClickEvents: (payload) => {
    return POST(config.releaseEvents.url, payload);
  },
};

export default event;