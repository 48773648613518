import React, { useEffect, useState } from 'react';
export default function TypeForm(props) {
    const contactNumber = props?.userDetails?.phone;
    console.log(contactNumber)
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//embed.typeform.com/next/embed.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            <div
            data-tf-sidetab="sYzvVH6P"
            data-tf-opacity="100"
            data-tf-iframe-props="title=Surgery OPD book form- gaurav"
            data-tf-open="time"
            data-tf-open-value="60000"
            data-tf-transitive-search-params
            data-tf-button-color="#0445AF"
            data-tf-button-text="Chat with our experts!"
            data-tf-medium="snippet"
            data-tf-hidden={`phone=${contactNumber}`}
            ></div>
        </>
  )
}