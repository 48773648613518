import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import { Route, HashRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import FAQ from './components/faq';
import { initialize } from 'react-ga';


ReactDOM.render(
  <HashRouter>
    <Route exact path='/' component={FAQ} />
    {/* <Route exact path='/work-risk-bot' component={workRiskChatBot} />
    <Route exact path='/result' component={ResultPage} />
    <Route exact path='/result-work-fit' component={WorkFitScanResultPage} />
    <Route exact path='/loading-results' component={loadingResults} />
    <Route exact path='/telemer' component={telemer} />
    <Route exact path='/hdfcdemo' component={shortHDFC} /> */}
  </HashRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initialize('G-VR4TP3NR5E');
