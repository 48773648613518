const { DAfaqsData, faqsData } = require("./faqData");

const mbRule = {
  color: {
    primary: '#1778f2',
    secondary: '#f7f7f7',
    ternary: '#050a4e',
    quarter: '#fff',
    disclaimer: 'rgb(232, 244, 253)'
  },
  data: faqsData
}

const daRule = {
  color: {
    primary: '#03A52D',
    secondary: '#f7f7f7',
    ternary: '#050a4e',
    quarter: '#fff',
    disclaimer: '#F6FEF0'
  },
  data: DAfaqsData
}

var rule = mbRule;

if(window.location.hostname == 'faq.docsapp.in'){
  rule = daRule;
}
if(window.location.hostname == 'faq.medibuddy.in' || window.location.hostname == 'faqs.medibuddy.in'){
  rule = mbRule;
}

module.exports = {
  rule
}